import { Button, Container,LinearProgress, Typography } from "@material-ui/core";
import { AdminPageLayout } from "components/Layout/AdminPageLayout";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import orderBy from "lodash/orderBy";
import { useFetchGuarantees } from "../hooks/useFetchGuarantees";
import { GuaranteesCard } from "../components/GuaranteesCard";

export const GuaranteesPage: React.FC = () => {
  const {guarantees, isFetched} = useFetchGuarantees(); 

  const orderedGuarantees = orderBy(guarantees, "order");

  return (
    <AdminPageLayout>
      <Container maxWidth={false}>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <Typography component="h2" variant="h6" color="primary" className="tw-my-4">Garanties</Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>Ajouter</Button>
        </div>
        {isFetched ? (
          <>
            {orderedGuarantees.map(guarantee => {
              return ( 
                <GuaranteesCard key={guarantee.id} guarantee={guarantee} />
              );
            })}
          </>
        ) : (
          <LinearProgress />
        )}
      </Container>
    </AdminPageLayout>
  );
};