import { useIsAuthenticated } from "@azure/msal-react";
import { ThemeProvider } from "@material-ui/core";
import { msalInstance } from "index";
import { registerUser } from "modules/account/actions";
import { ClauseCategoriesPage } from "modules/admin/views/ClauseCategoriesPage";
import { ClausesPage } from "modules/admin/views/ClausesPage";
import { GuaranteesPage } from "modules/admin/views/GuaranteesPage";
import { WorktypesPage } from "modules/admin/views/WorktypesPage";
import { startApplication } from "modules/application/actions";
import { PrivateRoute } from "modules/application/components/PrivateRoute";
import { ExternalLoginPage } from "modules/application/views/ExternalLoginPage";
import { SplashScreen } from "modules/application/views/SplashScreen";
import { ForbiddenError } from "modules/errors/views/ForbiddenError";
import { InternalServerError } from "modules/errors/views/InternalServerError";
import { NotFoundError } from "modules/errors/views/NotFoundError";
import { UnauthorizedError } from "modules/errors/views/UnauthorizedError";
import { QuoteGenerator } from "modules/quote/views/QuoteGenerator";
import { QuoteSearch } from "modules/quote/views/QuoteSearch";
import { theme } from "muiTheme";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Route, Switch } from "react-router";
import { RootState } from "store";

export const App: React.FC = () => {
  const dispatch = useDispatch();
  const isLoaded = useSelector((state: RootState) => state.application.isLoaded);
  const isAuthenticated = useIsAuthenticated();
  
  useEffect(() => {
    dispatch(startApplication());
  }, [dispatch]);

  useEffect(() => {
    if(isAuthenticated) {
      const activeAccount = msalInstance.getAllAccounts()[0];
      dispatch(registerUser(activeAccount?.name || ""));
    }
  }, [isAuthenticated]);
  
  return (
    <ThemeProvider theme={theme}>
      {isLoaded ? (
        <Switch>
          <PrivateRoute exact path="/" component={QuoteSearch} />
          <PrivateRoute exact path="/propositions/:propositionId" component={QuoteGenerator} />
          <PrivateRoute exact path="/admin" component={ClausesPage} />
          <PrivateRoute exact path="/admin/clausecategories" component={ClauseCategoriesPage} />
          <PrivateRoute exact path="/admin/guarantees" component={GuaranteesPage} />
          <PrivateRoute exact path="/admin/worktypes" component={WorktypesPage} />

          <Route exact path="/error/404" component={NotFoundError} />
          <Route exact path="/error/500" component={InternalServerError} />
          <Route exact path="/error/401" component={UnauthorizedError} />
          <Route exact path="/error/403" component={ForbiddenError} />

          <Route exact path="/login" component={ExternalLoginPage} />
        </Switch>
      ) : (
        <SplashScreen />
      )}
    </ThemeProvider>
  );
};
