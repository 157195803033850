import { delay, put, takeEvery } from "redux-saga/effects";
import * as actionsTypes from "./actionTypes";
import { startApplicationCompleted } from "./actions";
import { SagaIterator } from "redux-saga";
function* startApplication() {
  yield delay(1000);
  
  yield put(startApplicationCompleted("fr-CA"));
}

export function* applicationSagas(): SagaIterator {
  yield takeEvery(actionsTypes.START_APPLICATION, startApplication);
}

