import { Box, FormControl, FormControlLabel, FormHelperText, Switch, TextField, Typography } from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import React, { useEffect } from "react";
import { Controller, FieldError, FieldValues, UseFieldArrayReturn, useFormContext, useWatch } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Clause, ClauseValue, ClauseValueWithIndex, WorkType, WorkTypeOptions } from "../types";
import { OtherWorkSection } from "./OtherWorkSection";
import { QuoteClauseSection } from "./QuoteClauseSection";
import { groupBy, orderBy } from "lodash";
import { FormValues } from "./QuoteGeneratorForm";
import { useFormValidations } from "../hooks/useFormValidations";

const m = defineMessages({
  workType: {
    id: "WorkDescription.WorkType",
    defaultMessage: "Type de travaux",
  },
  workLabel: {
    id: "WorkDescription.WorkLabel",
    defaultMessage: "Travaux",
  },
  workAdditionalSpecifications: {
    id: "WorkDescription.additionalSpecifications",
    defaultMessage: "Spécifications additionnelles",
  },
  workHasStagesLabel: {
    id: "WorkDescription.workHasStagesLabel",
    defaultMessage: "Exécution progressive des travaux",
  },
});

export interface WorkDescriptionFormProps {
    workTypes: WorkType[],
    fieldArrayMethods: UseFieldArrayReturn<FieldValues, string, "key">
}

export const WorkDescriptionForm: React.FC<WorkDescriptionFormProps> = (props: WorkDescriptionFormProps) => { 

  const { formatMessage } = useIntl();
  const validations = useFormValidations();

  const { control, setValue, getValues, formState } = useFormContext<FormValues>();

  const clausesWatch = useWatch({
    control,
    name: "workDescription.clauses",
    defaultValue: []
  });

  useEffect(() => {
    const selectedWorkTypeOption = getValues("workDescription.workTypes");
    const stateClauses = getValues("workDescription.clauses") || [];
    const selectedWorkTypes = props.workTypes.filter(workType => selectedWorkTypeOption.some(v => v.id === workType.id));

    const clauses = selectedWorkTypes.reduce<Clause[]>((previous, current) => {
      return previous.concat(current.clauses);
    }, []);

    const toRemove = [] as number[];
    stateClauses.forEach((value, index) => {
      if(clauses.every(c => c.id !== value.id)){
        toRemove.push(index);
      }
    });
        
    if(toRemove.length > 0){
      props.fieldArrayMethods.remove(toRemove);
    }
  }, [props.fieldArrayMethods]);

  const setClauses = (value: WorkTypeOptions[]) => {
    const selectedWorkTypes = props.workTypes.filter(workType => value.some(v => v.id === workType.id));

    const clauses = selectedWorkTypes.reduce<Clause[]>((previous, current) => {
      return previous.concat(current.clauses);
    }, []);

    const stateClauses = getValues("workDescription.clauses");
    const toAdd = [] as ClauseValue[];
    clauses.forEach((value) => {      
      if(stateClauses.every(c => c.id !== value.id)){
        toAdd.push({
          ...value,
          isEnable: false,
          responsible: "Company",
          order: 999 + value.order,
          variables: []
        });
      }
    });
    props.fieldArrayMethods.append(toAdd);
  };

  const handleWorkTypeChange = (event: React.ChangeEvent<unknown>, value: WorkTypeOptions[]) => {
    setValue("workDescription.workTypes", value, { shouldValidate: true });
    setClauses(value);
  };

  const workTypeOptions = props.workTypes.reduce<WorkTypeOptions[]>((previous, current) => {
    previous.push({
      id: current.id,
      name: current.name
    });
    return previous;
  },[]);

  const clausesWithIndex = (clausesWatch || []).reduce<ClauseValueWithIndex[]>((previousValue, currentValue, currentIndex) => {
    previousValue.push({
      ...currentValue,
      index: currentIndex,
      key: props.fieldArrayMethods.fields[currentIndex].key
    });

    return previousValue;
  }, []);

  const renderCategoryClauses = (clausesGroupByCategory:ClauseValueWithIndex[][]) => {
    let activeIndex = 0;

    return Object.entries(clausesGroupByCategory).map(([categoryId, clauses]) => {
      const isActive = clauses.some(c=>c.isEnable);

      if(isActive) {
        activeIndex ++;
      }
      return (
        <QuoteClauseSection key={categoryId} clauses={clauses} index={activeIndex} />
      );
    });
  };

  const clausesGroupByCategory = groupBy(clausesWithIndex, x => x.clauseCategory.id);
  const clausesGroupByCategoryOrdered = orderBy(clausesGroupByCategory, x => x[0].clauseCategory.order);

  const workTypeFieldError = formState.errors.workDescription?.workTypes as unknown as FieldError;

  return (
    <Box className="tw-p-1 tw-w-full">
      <Box className="tw-mt-4 tw-w-full">
        <FormControl variant="outlined" fullWidth>
          <Controller
            control={control}
            rules={{ validate: validations.worktypes }}
            name="workDescription.workTypes"
            defaultValue={[]}
            render={renderProps => {
              // eslint-disable-next-line @typescript-eslint/no-unused-vars
              const { onChange, ...rest } = renderProps.field;
              return(
                <Autocomplete
                  multiple
                  id="worktype"
                  options={workTypeOptions}
                  getOptionLabel={(option) => option.name}
                  getOptionSelected={(option, value) => option.id === value.id}
                  onChange={handleWorkTypeChange}
                  filterSelectedOptions
                  fullWidth
                  {...rest}
                  renderInput={(params) => (
                    <TextField
                      error={Boolean(workTypeFieldError)}
                      required
                      {...params}
                      variant="outlined"
                      label={formatMessage(m.workType)}
                      placeholder={formatMessage(m.workType)}
                    />
                  )}
                />
              );}
            }
          />
          {!!workTypeFieldError && (
            <FormHelperText error>
              {workTypeFieldError.message}
            </FormHelperText>
          )}
        </FormControl>
      </Box>
      <Box className="tw-mt-4 tw-w-full">
        <Typography variant="subtitle2" className="tw-mb-1">{formatMessage(m.workLabel)}</Typography>
        {renderCategoryClauses(clausesGroupByCategoryOrdered)}
        <OtherWorkSection index={clausesGroupByCategoryOrdered.filter(x => x.some(c=> c.isEnable)).length + 1} />
      </Box>
      <Controller
        control={control}
        name="workDescription.additionalSpecifications"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <TextField
            id="workremarks"
            className="tw-mt-4"
            multiline
            label={formatMessage(m.workAdditionalSpecifications)}
            fullWidth
            rows={6}
            variant="outlined"
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            ref={ref}
          />
        )}
      />
      <Box className="tw-mt-4 tw-w-full">
        <FormControlLabel control={
          <Controller
            name={"workDescription.hasStages"}
            control={control}
            render={({field}) => (
              <Switch
                {...field}
                onChange={(e,v)=> {field.onChange(v);}}
                checked={field.value}
                color="primary"
              />
            )}
          />
        } label={formatMessage(m.workHasStagesLabel)} />
      </Box>
    </Box>
  );
};