import { Button, Container, LinearProgress, Typography } from "@material-ui/core";
import { AdminPageLayout } from "components/Layout/AdminPageLayout";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import orderBy from "lodash/orderBy";
import { useWorkTypes } from "modules/quote/hooks/useWorkTypes";
import { WorktypeCard } from "../components/WorktypeCard";

export const WorktypesPage: React.FC = () => {
  const {workTypes, isFetched} = useWorkTypes(); 

  const orderedWorktypes = orderBy(workTypes, "name");

  return (
    <AdminPageLayout>
      <Container maxWidth={false}>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <Typography component="h2" variant="h6" color="primary" className="tw-my-4">Types de travaux</Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>Ajouter</Button>
        </div>

        {isFetched ? (
          <>
            {orderedWorktypes.map(worktype => {
              return ( 
                <WorktypeCard key={worktype.id} worktype={worktype} />
              );
            })}
          </>
        ) : (
          <LinearProgress />
        )}
  
      </Container>
    </AdminPageLayout>
  );
};