import { Accordion, AccordionDetails, TextField, Typography, withStyles } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";

const AccordionSummary = withStyles({
  root: {
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expandIcon: {
    order: -1,
    marginRight: 12,
    paddingLeft: 0,
    paddingRight: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

const m = defineMessages({
  title: {
    id: "OtherWorkSection.Title",
    defaultMessage: "Autres travaux",
  }
});

export interface OtherWorkSectionProps {
    index: number
}

export const OtherWorkSection: React.FC<OtherWorkSectionProps> = (props: OtherWorkSectionProps) => { 

  const { formatMessage } = useIntl();
  const { control } = useFormContext();

  return (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <Typography variant="subtitle2">{props.index}. {formatMessage(m.title)}</Typography>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <Controller
          control={control}
          name="workDescription.otherWorkClause"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              id="otherwork"
              multiline
              fullWidth
              rows={6}
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </AccordionDetails>
    </Accordion>
  );
};