import { Box, Card, CardActions, CardContent, Grid, IconButton, Typography } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { ClauseCategory } from "modules/quote/types";

export interface ClauseCategoryCardProps {
  clauseCategory: ClauseCategory;
}

export const ClauseCategoryCard: React.FC<ClauseCategoryCardProps> = (props:ClauseCategoryCardProps) => {

  return (
    <Card className="tw-my-2">
      <Grid container direction="row" alignItems="center" className="tw-flex-nowrap">
        <CardContent className="tw-flex tw-flex-grow tw-p-2">
          <Box>
            <Typography variant="body1" component="h3">{props.clauseCategory.name}</Typography>
          </Box>
        </CardContent>
        <CardActions>
          <IconButton color="primary" size="small">
            <EditIcon />
          </IconButton>
          <IconButton size="small">
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Grid>
    </Card>                
  );
};