import { Card, CardActions, CardContent, Grid, IconButton } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import React from "react";
import { Guarantee } from "../types";

export interface GuaranteesCardProps {
  guarantee: Guarantee;
}

export const GuaranteesCard: React.FC<GuaranteesCardProps> = (props:GuaranteesCardProps) => {

  return (
    <Card className="tw-my-2">
      <Grid container direction="row" alignItems="center" className="tw-flex-nowrap">
        <CardContent className="tw-flex tw-flex-grow tw-p-2">
          <div>{props.guarantee.text}</div>
        </CardContent>
        <CardActions>
          <IconButton color="primary" size="small">
            <EditIcon />
          </IconButton>
          <IconButton size="small">
            <DeleteIcon />
          </IconButton>
        </CardActions>
      </Grid>
    </Card>              
  );
};