import { defaultApi, requests } from "api";
import { AxiosResponse } from "axios";
import { Proposition, PropositionSearchResult, WorkType } from "./types";

export interface GenerateQuotationApiData {
  opportunityId: string;
  date: string;
  language: string;
  summary: string;
  amount: number;
  paymentModalitySignaturePercentage: number;
  paymentModalityMobilisationPercentage: number;
  paymentModalityHalfworkPercentage: number;
  additionalSpecifications: string;
  worktypesIds: number[];
  hasStages: boolean;
  clauses: {
    id: number;
    order: number;
    responsible: string;
    variables: string[];
  }[];
  otherWorkClause: string;
  salesPersonName: string;
  salesPersonFunction: string;
  salesPersonAbreviation: string;
}

const apiWorkTypeBaseUrl = "/api/worktypes";
const apiPropositionBaseUrl = "/api/propositions";
const apiQuotationBaseUrl = "/api/quotation";

export const WorkTypeApi = {
  fetchAllWorkTypes: (): Promise<WorkType[]> =>
    requests.get<WorkType[]>(`${apiWorkTypeBaseUrl}`),
};

export const PropositionsApi = {
  fetchProposition: (propisitionId: string): Promise<Proposition> =>
    requests.get<Proposition>(`${apiPropositionBaseUrl}/${propisitionId}`),
  searchPropositions: (query: string): Promise<PropositionSearchResult[]> =>
    requests.get(`${apiPropositionBaseUrl}/search?query=${query}`),
};

export const QuotationsApi = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  generateQuotation: (data: GenerateQuotationApiData): Promise<AxiosResponse<any>> =>
    defaultApi.request({
      method: "POST",
      url: apiQuotationBaseUrl,
      responseType: "blob",
      data: data,
    }),
};
