import * as actionsTypes from "./actionTypes";
import { AccountActionTypes } from ".";

export function registerDemoUser(): AccountActionTypes {
  return {
    type: actionsTypes.REGISTER_DEMO_USER,
  };
}

export function registerUser(userName: string): AccountActionTypes {
  return {
    type: actionsTypes.REGISTER_USER,
    payload: userName,
  };
}

export function clearAccount() : AccountActionTypes {
  return {
    type: actionsTypes.CLEAR_ACCOUNT
  };
}