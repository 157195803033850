import { Button, Container, LinearProgress, Typography } from "@material-ui/core";
import { AdminPageLayout } from "components/Layout/AdminPageLayout";
import AddIcon from "@material-ui/icons/Add";
import React, { useState } from "react";
import orderBy from "lodash/orderBy";
import groupBy from "lodash/groupBy";
import { useFetchClauses } from "../hooks/useFetchClauses";
import { ClauseCard } from "../components/ClauseCard";
import { CreateClauseFormDialog } from "../components/CreateClauseFormDialog";

export const ClausesPage: React.FC = () => {
  const [version, setVersion] = useState(0);
  const {clauses, isFetched} = useFetchClauses(version); 
  const [openFormDialog, setOpenFormDialog] = React.useState(false);

  const orderedClauses = orderBy(clauses, "order");
  const clausesGroupByCategory = groupBy(orderedClauses, x => x.clauseCategory.id);
  const clausesGroupByCategoryOrdered = orderBy(clausesGroupByCategory, x => x[0].clauseCategory.order);

  const refreshClauses = () => {
    setVersion(version+1);
  };

  const handleClauseSaved = () => {
    refreshClauses();
  };

  const handleDialogClickOpen = () => {
    setOpenFormDialog(true);
  };

  const handleDialogClose = () => {
    setOpenFormDialog(false);
  };

  const handleOnSaved = () => {
    setOpenFormDialog(false);
    handleClauseSaved();
  };

  const handleOnDelete = () => {
    refreshClauses();
  };

  return (
    <AdminPageLayout>
      <Container maxWidth={false}>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <Typography component="h2" variant="h6" color="primary" className="tw-my-4">Clauses</Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />} onClick={handleDialogClickOpen}>Ajouter</Button>
        </div>

        {isFetched ? (
          <>
            {Object.entries(clausesGroupByCategoryOrdered).map(([category, clauses])  => {
              return ( 
                <div key={category} className="tw-mb-8">
                  <Typography variant="subtitle2" component="h3" className="tw-text-gray-500">{clauses[0].clauseCategory.name}</Typography>
                  {clauses.map(clause => {
                    return ( 
                      <ClauseCard key={clause.id} clause={clause} onClauseSaved={handleClauseSaved} onClauseDeleted={handleOnDelete} />
                    );
                  })}
                </div>
              );
            })}          
          </>
        ) : (
          <LinearProgress />
        )}
      </Container>
      {openFormDialog && <CreateClauseFormDialog isOpen={openFormDialog} handleClose={handleDialogClose} onSaved={handleOnSaved} />}
    </AdminPageLayout>
  );
};