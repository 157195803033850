import { defaultErrorHandler } from "api";
import axios from "axios";
import { ClauseCategory } from "modules/quote/types";
import { useEffect, useState } from "react";
import { ClauseCategoriesApi } from "../api";

interface Output {
  clauseCategories: ClauseCategory[];
    isFetched: boolean;
}
  
export const useFetchClauseCategories = () : Output => {

  const [clauseCategories, setClauseCategories] = useState<ClauseCategory[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
        
    ClauseCategoriesApi.getAll(source)
      .then((fetchedClauseCategories) => {
        setClauseCategories(fetchedClauseCategories);
        setIsFetched(true);
      })
      .catch((error) => {
        setClauseCategories([]);
        setIsFetched(false);

        if(!axios.isCancel(error)){
          defaultErrorHandler(error);
        }
      });

    return() => {
      source.cancel();
    };

  }, []);

  return { clauseCategories, isFetched };
};