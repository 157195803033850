import { defaultErrorHandler } from "api";
import axios from "axios";
import { Clause } from "modules/quote/types";
import { useEffect, useState } from "react";
import { ClausesApi } from "../api";

interface Output {
  clauses: Clause[];
  isFetched: boolean;
}
  
export const useFetchClauses = (version: number) : Output => {

  const [clauses, setClauses] = useState<Clause[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    setIsFetched(false);
    const source = axios.CancelToken.source();
        
    ClausesApi.getAll(source)
      .then((fetchedClauses) => {
        setClauses(fetchedClauses);
        setIsFetched(true);
      })
      .catch((error) => {
        setClauses([]);
        setIsFetched(false);

        if(!axios.isCancel(error)){
          defaultErrorHandler(error);
        }
      });

    return() => {
      source.cancel();
    };

  }, [version]);

  return { clauses, isFetched };
};