import * as actionsTypes from "./actionTypes";

export interface WorkTypeOptions {
    id: number;
    name: string;
}

export interface WorkType {
    id: number;
    name: string;
    clauses: Clause[];
    nameI18n?: Record<string, string>;
}

export interface Proposition {
    opportunityId: string;
    client1Name: string;
    client1Title: Titles;
    client2Name: string;
    client2Title: Titles;
    clientCompanyName: string;
    clientStreet: string;
    clientPostalCode: string;
    clientCity: string;
    clientProvince: Provinces;
    clientTelephone: string;
    clientCellPhone: string;
    clientOfficePhone: string;
    clientEmail: string;
    propositionNumber: string;
    workStreet: string;
    workPostalCode: string;
    workCity: string;
    workProvince: Provinces;
    workRemarks: string;
    workCostEstimate: number;
    language: string;
    salesPersonName: string;
    salesPersonFunction: string;
    salesPersonAbreviation: string;
}

export interface PropositionSearchResult {
    opportunityId: string;
    propositionNumber: string;
    workAddress: string;
    client1: string;
    client2: string;
}

export enum Titles {
    None,
    Mister,
    Madam,
    Sir,
    Mrs,
}

export enum Provinces {
    None,
    Alberta,
    BritishColumbia,
    PrinceEdwardIsland,
    Manitoba,
    NewBrunswick,
    NovaScotia,
    Nunavut,
    Ontario,
    Quebec,
    Saskatchewan,
    NewfoundlandLabrador,
    NorthwestTerritories,
    Yukon,
}

export interface ClauseValue {
    id: number;
    key?: string;
    order: number;
    responsible: ClauseResponsible;
    canChooseResponsible: boolean;
    name: string;
    description: string;
    text: string;
    isEnable: boolean;
    clauseCategory: ClauseCategory;
    variables: string[];
}

export interface ClauseValueWithIndex extends ClauseValue {
    index: number;
}

export interface Clause {
    id: number;
    order: number;
    canChooseResponsible: boolean;
    name: string;
    nameI18n?: Record<string, string>;
    description: string;
    text: string;
    textI18n?: Record<string, string>;
    clauseCategory: ClauseCategory;
    workTypes: WorkType[];
}

export interface ClauseCategory {
    id: number;
    order: number;
    name: string;
    nameI18n?: Record<string, string>;
}

export interface QuoteClause {
    id: string;
    content: string;
}

export type QuoteClauseResponsibility = "Polar Breeze" | "Client";

export interface QuoteState {
    clauses: Clause[]
}

interface UpdateQuoteClausesAction {
    type: typeof actionsTypes.UPDATE_QUOTE_CLAUSES;
    payload: { clauses: Clause[] };
  }
  
export type QuoteActionTypes =
    | UpdateQuoteClausesAction;
  
export type ClauseResponsible = "Company" | "Client";