import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress } from "@material-ui/core";

export interface DeleteDialogProps {
  title: string;
  isOpen: boolean;
  handleClose: ()=>void;
  onDelete: ()=>void;
}

export const DeleteDialog: React.FC<DeleteDialogProps> = (props:DeleteDialogProps) => {
  const [isDeleting, setIsDeleting] = React.useState(false);

  const handleOnDeleteClick = () => {
    setIsDeleting(true);
    props.onDelete();
  };

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
      <DialogTitle>{props.title}</DialogTitle>    
      <DialogContent dividers>
        Etes vous certain de vouloir supprimer?
      </DialogContent>
      {isDeleting && <LinearProgress />}
      <DialogActions>
        <Button onClick={props.handleClose} disabled={isDeleting}>Annuler</Button>
        <Button onClick={handleOnDeleteClick} variant="contained" color="secondary" disabled={isDeleting}>Supprimer</Button>
      </DialogActions>
    </Dialog> 
  );
};