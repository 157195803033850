import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ErrorPage } from "../components/ErrorPage";
import { ReactComponent as PageNotFoundIcon } from "icons/undraw_page_not_found.svg";

const m = defineMessages({
  title: {
    id: "Errors.NotFoundError.Title",
    defaultMessage: "Oups...on dirait que tu t'es perdu",
  },
  message: {
    id: "Errors.NotFoundError.Message",
    defaultMessage: "La page que tu recherche n'existe pas.",
  }
});

export const NotFoundError : React.FC = () => {
  const { formatMessage } = useIntl();
  
  return (
    <ErrorPage
      errorCode={404}
      title={formatMessage(m.title)}
      message={formatMessage(m.message)}
      svgImage={<PageNotFoundIcon />}
    />
  );
};