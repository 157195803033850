import React, { useEffect, useMemo } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress } from "@material-ui/core";
import { useFetchClause } from "../hooks/useFetchClause";
import { FormProvider, useForm } from "react-hook-form";
import { ClauseCategory, WorkType } from "modules/quote/types";
import { ClausesApi, UpdateClauseCommand } from "../api";
import { ApiError, defaultErrorHandler } from "api";
import { ClauseForm } from "./ClauseForm";

export type FormValues = {
  nameI18n: Record<string, string>;
  textI18n: Record<string, string>;
  order: number;
  canChooseResponsible: boolean;
  clauseCategory: ClauseCategory;
  workTypes: WorkType[];
}

export interface UpdateClauseFormDialogProps {
  isOpen: boolean;
  handleClose: ()=>void;
  onSaved: ()=>void;
  id: number;
}

export const UpdateClauseFormDialog: React.FC<UpdateClauseFormDialogProps> = (props:UpdateClauseFormDialogProps) => {
  const {clause, isFetched} = useFetchClause(props.id); 
  const [isSaving, setIsSaving] = React.useState(false);

  const methods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return clause;
    }, [clause])
  });

  const handleOnSubmit = async (data: FormValues) => {   
    setIsSaving(true);

    const command: UpdateClauseCommand = {
      canChooseResponsible: data.canChooseResponsible,
      clauseCategoryId: data.clauseCategory.id,
      order: data.order,
      nameI18n: data.nameI18n,
      textI18n: data.textI18n,
      worktypesIds: data.workTypes.map((value) => (value.id))
    };

    try {
      await ClausesApi.update(props.id, command);
      props.onSaved();
    } catch (error) {
      defaultErrorHandler(error as ApiError);
    }
  };

  const { reset } = methods;

  useEffect(() => {
    reset(clause);
  }, [clause]);

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
      <DialogTitle>Editer la clause</DialogTitle>
      
      {isFetched ? (
        <>
          <DialogContent dividers>
            <FormProvider {...methods} >
              <ClauseForm isSaving={isSaving} />
            </FormProvider>
          </DialogContent>
          {isSaving && <LinearProgress />}
          <DialogActions>
            <Button onClick={props.handleClose} disabled={isSaving}>Annuler</Button>
            <Button onClick={methods.handleSubmit(handleOnSubmit)} variant="contained" color="primary" disabled={isSaving}>Sauvegarder</Button>
          </DialogActions>
        </>
      ) : (
        <LinearProgress />
      )}
    </Dialog> 
  );
};