/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from "react";
import { PageLayout } from "components/Layout/PageLayout";
import { Container, LinearProgress } from "@material-ui/core";
import { useWorkTypes } from "../hooks/useWorkTypes";
import { useProposition } from "../hooks/useProposition";
import { QuoteGeneratorForm } from "../components/QuoteGeneratorForm";
import { useParams } from "react-router";

interface Params {
  propositionId: string;
}

export const QuoteGenerator: React.FC = () => {  

  const params = useParams<Params>();
  const { workTypes, isFetched: isWorkTypesFetched } = useWorkTypes();
  const { proposition, isFetched: isPropositionFetched } = useProposition(params.propositionId);
  const isLoaded = isWorkTypesFetched && isPropositionFetched;

  return (
    <PageLayout>      
      {isLoaded ? (
        <Container maxWidth="lg">
          <QuoteGeneratorForm proposition={proposition!} workTypes={workTypes} />
        </Container>
      ):(
        <LinearProgress />
      )}     
    </PageLayout>
  );
};
