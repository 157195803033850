import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useSelector } from "react-redux";
import { RootState } from "store";

export const PrivateRoute: React.FC<RouteProps> = (props) => {

  const isAuthenticated = useSelector((state: RootState) => state.account.isAuthenticated);

  return (
    <Route
      render={({ location }) =>
        isAuthenticated ? (
          <Route {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};
