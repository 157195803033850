import { Box } from "@material-ui/core";
import React from "react";
import { DragDropContext, Droppable, DropResult} from "react-beautiful-dnd";
import { useFormContext } from "react-hook-form";
import { ClauseValueWithIndex } from "../types";
import { QuoteClauseItem } from "./QuoteClauseItem";
import { FormValues } from "./QuoteGeneratorForm";

export interface QuoteClauseListProps {
    index: number;
    clauses: ClauseValueWithIndex[]
}


export const QuoteClauseList: React.FC<QuoteClauseListProps> = (props: QuoteClauseListProps) => { 

  const { setValue } = useFormContext<FormValues>();

  const grid = 8;

  const reorder = (list: ClauseValueWithIndex[], startIndex: number, endIndex: number) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);
      
    return result;
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      return;
    }

    const newClauses = reorder(
      props.clauses,
      result.source.index,
      result.destination.index
    );

    newClauses.forEach((clause, index) => {
      setValue(`workDescription.clauses.${clause.index}.order`, index + 1);
    });
  };

  const getListStyle = (isDraggingOver: boolean) => ({
    background: isDraggingOver ? "#24F0D5" : "#EDEDED",
    padding: grid
  });

  return (
    <Box className="tw-w-full" >
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => {
            let clauseNumber = 0;
            return (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {props.clauses.map((clause, index) => {
                  if(clause.isEnable) {
                    clauseNumber++;
                  }
                  return (
                    <QuoteClauseItem key={clause.key} clause={clause} sectionNumber={props.index} clauseNumber={clauseNumber} index={index} grid={grid} />
                  );
                })}
                {provided.placeholder}
              </div>
            );}}
        </Droppable>
      </DragDropContext>
    </Box>
  );
};