import React from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, LinearProgress } from "@material-ui/core";
import { FormProvider, useForm } from "react-hook-form";
import { ClauseCategory, WorkType } from "modules/quote/types";
import { ClausesApi, CreateClauseCommand } from "../api";
import { ApiError, defaultErrorHandler } from "api";
import { ClauseForm } from "./ClauseForm";

export type FormValues = {
  nameI18n: Record<string, string>;
  textI18n: Record<string, string>;
  order: number;
  canChooseResponsible: boolean;
  clauseCategory: ClauseCategory;
  workTypes: WorkType[];
}

export interface CreateClauseFormDialogProps {
  isOpen: boolean;
  handleClose: ()=>void;
  onSaved: ()=>void;
}

export const CreateClauseFormDialog: React.FC<CreateClauseFormDialogProps> = (props:CreateClauseFormDialogProps) => {
  const [isSaving, setIsSaving] = React.useState(false);

  const methods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      canChooseResponsible: false,
      order:1
    }
  });

  const handleOnSubmit = async (data: FormValues) => {   
    setIsSaving(true);

    const command: CreateClauseCommand = {
      canChooseResponsible: data.canChooseResponsible,
      clauseCategoryId: data.clauseCategory.id,
      order: data.order,
      nameI18n: data.nameI18n,
      textI18n: data.textI18n,
      worktypesIds: data.workTypes.map((value) => value.id)
    };

    try {
      await ClausesApi.create(command);
      props.onSaved();
    } catch (error) {
      defaultErrorHandler(error as ApiError);
    }
  };

  return (
    <Dialog open={props.isOpen} onClose={props.handleClose} fullWidth>
      <DialogTitle>Nouvelle clause</DialogTitle>    
      <DialogContent dividers>
        <FormProvider {...methods} >
          <ClauseForm isSaving={isSaving} />
        </FormProvider>
      </DialogContent>
      {isSaving && <LinearProgress />}
      <DialogActions>
        <Button onClick={props.handleClose} disabled={isSaving}>Annuler</Button>
        <Button onClick={methods.handleSubmit(handleOnSubmit)} variant="contained" color="primary" disabled={isSaving}>Créer</Button>
      </DialogActions>
    </Dialog> 
  );
};