import { Box, Grid, LinearProgress } from "@material-ui/core";
import { Typography } from "@material-ui/core";
import { isEmpty } from "lodash";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useSearchPropositions } from "../hooks/useSearchPropositions";
import { PropositionSearchCard } from "./PropositionSearchCard";
import { ReactComponent as EmptySearch } from "icons/undraw_the_search.svg";
import { ReactComponent as NewSearch } from "icons/undraw_Web_search.svg";
import { Container } from "@material-ui/core";

const m = defineMessages({
  title: {
    id: "PropositionSearchResults.Title",
    defaultMessage: "Résultats",
  },
  emptySearchTitle: {
    id: "PropositionSearchResults.EmptySearchTitle",
    defaultMessage: "Aucun résultat trouvé",
  },
  emptySearchSubTitle: {
    id: "PropositionSearchResults.EmptySearchSubTitle",
    defaultMessage: "Nous n'avons trouvé aucune proposition pour votre recherche.",
  },
  startSearchTitle: {
    id: "PropositionSearchResults.StartSearchTitle",
    defaultMessage: "Commencer votre recherche",
  },
  startSearchSubTitle: {
    id: "PropositionSearchResults.StartSearchSubTitle",
    defaultMessage: "Utilisez la barre de recherche pour trouver une proposition.",
  }
});

export interface PropositionSearchResultsProps {
    query: string;
}

export const PropositionSearchResults: React.FC<PropositionSearchResultsProps> = (props: PropositionSearchResultsProps) => { 
  const { formatMessage } = useIntl();
  const { propositions, isFetched: isPropositionsFetched } = useSearchPropositions(props.query);

  return (
    <>
      {isPropositionsFetched ? (
        <Container maxWidth="lg">
          {propositions.length > 0 ? (
            <>
              <Typography component="h2" variant="h6" color="primary" className="tw-my-4">{formatMessage(m.title)}</Typography>
              {propositions.map((proposition, index) => {
                return ( 
                  <PropositionSearchCard key={index} proposition={proposition}/>
                );
              })}
            </>
          ) : (
            <>
              {isEmpty(props.query) ? (
                <Box className="tw-flex tw-flex-grow tw-mt-8">
                  <Grid container direction="column" justify="center" alignItems="center">
                    <Typography align="center" component="h2" variant="h6">{formatMessage(m.startSearchTitle)}</Typography>
                    <NewSearch className="tw-w-2/5 tw-my-2 tw-max-w-lg" />
                    <Typography align="center">{formatMessage(m.startSearchSubTitle)}</Typography>
                  </Grid>
                </Box>
              ) : (
                <Box className="tw-flex tw-flex-grow tw-mt-8">
                  <Grid container direction="column" justify="center" alignItems="center">
                    <Typography align="center" component="h2" variant="h6">{formatMessage(m.emptySearchTitle)}</Typography>
                    <EmptySearch className="tw-w-2/5 tw-my-2 tw-max-w-lg" />
                    <Typography align="center">{formatMessage(m.emptySearchSubTitle)}</Typography>
                  </Grid>
                </Box>
              )}
            </>
          )}
        </Container>
      ) : (
        <LinearProgress />
      )}
    </> 
  );  
};