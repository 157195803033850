import { defaultErrorHandler } from "api";
import axios from "axios";
import { useEffect, useState } from "react";
import { GuaranteesApi } from "../api";
import { Guarantee } from "../types";

interface Output {
  guarantees: Guarantee[];
  isFetched: boolean;
}
  
export const useFetchGuarantees = () : Output => {

  const [guarantees, setGuarantees] = useState<Guarantee[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
        
    GuaranteesApi.getAll(source)
      .then((fetchedGuarantees) => {
        setGuarantees(fetchedGuarantees);
        setIsFetched(true);
      })
      .catch((error) => {
        setGuarantees([]);
        setIsFetched(false);

        if(!axios.isCancel(error)){
          defaultErrorHandler(error);
        }
      });

    return() => {
      source.cancel();
    };

  }, []);

  return { guarantees, isFetched };
};