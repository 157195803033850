import { Box, Grid, Paper, Typography } from "@material-ui/core";
import React from "react";

export interface FormContainerProps {
    title: string,
    subTitle: string
    children?: React.ReactNode;
}

export const FormContainer: React.FC<FormContainerProps> = (props: FormContainerProps) => { 
  return (
    <Paper className="tw-border tw-border-gray-300">
      <Box className="tw-bg-gray-100 tw-border-b tw-border-gray-300 tw-p-4">
        <Typography variant="h6" component="h2" className="tw-leading-none">{props.title}</Typography>
        <Typography variant="body2">{props.subTitle}</Typography>
      </Box>
      <Grid container spacing={1} className="tw-p-4">
        {props.children}
      </Grid>
    </Paper>
  );
};