import { Dayjs } from "dayjs";
import { defineMessages, useIntl } from "react-intl";
import { WorkTypeOptions } from "../types";

const m = defineMessages({
  dateIsRequired: {
    id: "Form.Validation.date.IsRequired",
    defaultMessage: "La date est requise",
  },
  amountIsRequired: {
    id: "Form.Validation.Amount.IsRequired",
    defaultMessage: "Le montant des travaux est requis",
  },
  salesPersonIsRequired: {
    id: "Form.Validation.SalesPerson.IsRequired",
    defaultMessage: "Le nom du vendeur est requis",
  },
  workTypeIsRequired: {
    id: "Form.Validation.Worktype.IsRequired",
    defaultMessage: "Un type de travaux est requis",
  }, 
  WorkCostBalanceIsValid: {
    id: "Form.Validation.WorkCost.BalanceIsValid",
    defaultMessage: "La balance doit être entre 0 et 100",
  }, 
});

const isPresent = (value: string): boolean => value !== "" && value !== null;

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const useFormValidations = () => {
  const { formatMessage } = useIntl();

  return {
    date: (value: Dayjs) => 
      value !== null || formatMessage(m.dateIsRequired),
    worktypes: (value: WorkTypeOptions[]) =>
      value.length > 0 || formatMessage(m.workTypeIsRequired),
    amount: (value: number) =>
      value > 0 || formatMessage(m.amountIsRequired),
    salesPerson: (value: string) =>
      isPresent(value) || formatMessage(m.salesPersonIsRequired),
    balance: (value: number) =>
      (value > 0 && value < 100) || formatMessage(m.WorkCostBalanceIsValid)
  };
};
