import { Button, Container, LinearProgress, Typography } from "@material-ui/core";
import { AdminPageLayout } from "components/Layout/AdminPageLayout";
import AddIcon from "@material-ui/icons/Add";
import React from "react";
import { useFetchClauseCategories } from "../hooks/useFetchClauseCategories";
import orderBy from "lodash/orderBy";
import { ClauseCategoryCard } from "../components/ClauseCategoryCard";

export const ClauseCategoriesPage: React.FC = () => {
  const {clauseCategories, isFetched} = useFetchClauseCategories(); 

  const orderedClauseCategories = orderBy(clauseCategories, "order");

  return (
    <AdminPageLayout>
      <Container maxWidth={false}>
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <Typography component="h2" variant="h6" color="primary" className="tw-my-4">Catégories de clause</Typography>
          <Button variant="contained" color="primary" startIcon={<AddIcon />}>Ajouter</Button>
        </div>

        {isFetched ? (
          <>
            {orderedClauseCategories.map(clauseCategory => {
              return ( 
                <ClauseCategoryCard key={clauseCategory.id} clauseCategory={clauseCategory} />
              );
            })}
          </>
        ) : (
          <LinearProgress />
        )}
  
      </Container>
    </AdminPageLayout>
  );
};