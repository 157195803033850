import { defaultErrorHandler } from "api";
import axios from "axios";
import { Clause } from "modules/quote/types";
import { useEffect, useState } from "react";
import { ClausesApi } from "../api";

interface Output {
  clause: Clause | undefined;
  isFetched: boolean;
}
  
export const useFetchClause = (id: number) : Output => {

  const [clause, setClause] = useState<Clause|undefined>();
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    const source = axios.CancelToken.source();
        
    ClausesApi.get(id, source)
      .then((fetchedClause) => {
        setClause(fetchedClause);
        setIsFetched(true);
      })
      .catch((error) => {
        setClause(undefined);
        setIsFetched(false);

        if(!axios.isCancel(error)){
          defaultErrorHandler(error);
        } 
      });

    return() => {
      source.cancel();
    };

  }, []);

  return { clause, isFetched };
};