import { history } from "index";

type DebugDetails = {
  stackTrace: string;
};

export class ApiError extends Error {
  public readonly statusCode: number;
  public readonly debugMessage?: string;
  constructor(
    statusCode: number,
    message?: string | undefined,
    debugMessage?: string,
    debugDetails?: DebugDetails
  ) {
    super(message);
    this.statusCode = statusCode;
    this.debugMessage = debugMessage;
    this.stack = debugDetails?.stackTrace;
  }
}

export const defaultErrorHandler = (
  error: ApiError
): void => {
  switch (error.statusCode) {
    case 401:
      history.push("/error/401");
      break;
    case 403:
      history.push("/error/403");
      break;
    case 404:
      history.push("/error/404");
      break;
    default:
      history.push("/error/500", error);
  }
};