import { Accordion, AccordionDetails, Box, Chip, Typography, withStyles } from "@material-ui/core";
import MuiAccordionSummary from "@material-ui/core/AccordionSummary";
import { QuoteClauseList } from "./QuoteClauseList";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";
import { ClauseValueWithIndex } from "../types";
import { orderBy } from "lodash";
import { useFormContext } from "react-hook-form";
import { FormValues } from "./QuoteGeneratorForm";

const AccordionSummary = withStyles({
  root: {
    "&$expanded": {
      minHeight: 48,
    },
  },
  content: {
    "&$expanded": {
      margin: "0",
    },
  },
  expandIcon: {
    order: -1,
    marginRight: 12,
    paddingLeft: 0,
    paddingRight: 0,
  },
  expanded: {},
})(MuiAccordionSummary);

export interface QuoteClauseSectionProps {
    index: number,
    clauses: ClauseValueWithIndex[],
}

export const QuoteClauseSection: React.FC<QuoteClauseSectionProps> = (props: QuoteClauseSectionProps) => {

  const [expanded, setExpanded] = React.useState(false);
  const { formState } = useFormContext<FormValues>();

  const categoryId = props.clauses[0].clauseCategory.id;
  const orderedClauses = orderBy(props.clauses, x => x.order);
  const activeClauses = props.clauses.filter(c => c.isEnable);
  const isEnabled = activeClauses.length > 0;
  const hasErrors = isEnabled && props.clauses.some(c => Boolean(formState.errors.workDescription?.clauses?.[c.index]));

  const renderTitle = () : string => {
    if(isEnabled)
    {
      return `${props.index}. ${props.clauses[0].clauseCategory.name}`;
    }
    else
    {
      return props.clauses[0].clauseCategory.name;
    }
  };

  const handleAccordionChange = () => {
    setExpanded(!expanded);
  };

  

  return (
    <Accordion className={hasErrors ? "tw-border-solid tw-border-2 tw-border-red" : ""} expanded={hasErrors || expanded} onChange={handleAccordionChange}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel-${categoryId}-content`}
        id={`panel-${categoryId}-header`}
      >
        <div className="tw-w-full tw-flex tw-items-center tw-justify-between">
          <Typography variant="subtitle2" className={isEnabled ? "" : "tw-text-gray-400"}>{renderTitle()}</Typography>
          <Box>
            <Typography variant="body2" component="span" className={isEnabled ? "tw-text-primary tw-font-bold": ""}>{activeClauses.length}</Typography>
            <Typography component="span" variant="body2">/{props.clauses.length}</Typography>
          </Box>
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <QuoteClauseList index={props.index} clauses={orderedClauses}/>
      </AccordionDetails>
    </Accordion>
  );
};