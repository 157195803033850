import { Reducer } from "redux";

import {
  CLEAR_ACCOUNT,
  REGISTER_DEMO_USER,
  REGISTER_USER,
} from "./actionTypes";

import { AccountActionTypes, AccountState } from "./types";

const initialState: AccountState = {
  user: {
    name: ""
  },
  isAuthenticated: false,
  isDemoUser:true,
};

export const accountReducer: Reducer<AccountState, AccountActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case REGISTER_DEMO_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          name: "Demo",
          isDemoUser: true
        }
      };
    case REGISTER_USER:
      return {
        ...state,
        isAuthenticated: true,
        user: {
          name: action.payload,
          isDemoUser: false
        }
      };
    case CLEAR_ACCOUNT:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};
