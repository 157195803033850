import { defaultErrorHandler } from "api";
import { isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { orderBy } from "lodash";
import { PropositionSearchResult } from "../types";
import { PropositionsApi } from "../api";

interface Output {
    propositions: PropositionSearchResult[];
    isFetched: boolean;
}
  
export const useSearchPropositions = (query: string) : Output => {

  const [propositions, setResults] = useState<PropositionSearchResult[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    if(!isEmpty(query))
    {
      setIsFetched(false);
      PropositionsApi.searchPropositions(query)
        .then((fetchedPropositionResults) => {
          const propositionsOrdered = orderBy(fetchedPropositionResults, (i => i.propositionNumber), ["desc"]);
          setResults(propositionsOrdered);
          setIsFetched(true);
        })
        .catch((error) => {
          setResults([]);
          setIsFetched(false);
          defaultErrorHandler(error);
        });
    }
    else{
      setResults([]);
      setIsFetched(true);
    }
  }, [query]);

  return { propositions, isFetched };
};