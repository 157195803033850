import { SvgIcon } from "@material-ui/core";
import React from "react";
import { ReactComponent as DragHandleIconSVG } from "icons/drag_handle.svg";

export interface DragHandleIconProps {
    className: string
}

export const DragHandleIcon: React.FC<DragHandleIconProps> = (props: DragHandleIconProps) => { 
  return (
    <SvgIcon {...props}>
      <DragHandleIconSVG />
    </SvgIcon>
  );
};