import { Grid, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { useFormValidations } from "../hooks/useFormValidations";

const m = defineMessages({
  name: {
    id: "SalesPersonInformationForm.Name",
    defaultMessage: "Nom",
  },
  function: {
    id: "SalesPersonInformationForm.Function",
    defaultMessage: "Fonction",
  },
  abreviation: {
    id: "SalesPersonInformationForm.Abreviation",
    defaultMessage: "Abréviation",
  }, 
});

export const SalesPersonInformationForm: React.FC = () => { 

  const { formatMessage } = useIntl();

  const { control, formState } = useFormContext();
  const validations = useFormValidations();

  return (
    <>
      <Grid item xs={12} md={3} className="tw-mt-4">
        <Controller
          control={control}
          rules={{ validate: validations.salesPerson }}
          name="salesPersonInformation.name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              required
              error={Boolean(formState.errors.salesPersonInformation?.name)}
              helperText={formState.errors.salesPersonInformation?.name?.message}
              variant="outlined"
              id="salesPersonName"
              label={formatMessage(m.name)}
              fullWidth
              autoComplete="name"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3} className="tw-mt-4">
        <Controller
          control={control}
          name="salesPersonInformation.function"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              variant="outlined"
              id="salesPersonFunction"
              label={formatMessage(m.function)}
              fullWidth
              autoComplete="sales-person-function"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} md={3} className="tw-mt-4">
        <Controller
          control={control}
          name="salesPersonInformation.abreviation"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              variant="outlined"
              id="salesPersonAbreviation"
              label={formatMessage(m.abreviation)}
              fullWidth
              autoComplete="sales-person-abreviation"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
    </>
  );
};