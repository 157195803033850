import { defaultErrorHandler } from "api";
import { useEffect, useState } from "react";
import { WorkTypeApi } from "../api";
import { WorkType } from "../types";

interface Output {
    workTypes: WorkType[];
    isFetched: boolean;
}
  
export const useWorkTypes = () : Output => {

  const [workTypes, setWorkTypes] = useState<WorkType[]>([]);
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    WorkTypeApi.fetchAllWorkTypes()
      .then((fetchedWorkTypes) => {
        setWorkTypes(fetchedWorkTypes);
        setIsFetched(true);
      })
      .catch((error) => {
        setWorkTypes([]);
        setIsFetched(false);
        defaultErrorHandler(error);
      });
  }, []);

  return { workTypes, isFetched };
};