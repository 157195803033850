import { FormControl, Grid, InputLabel, MenuItem, Select, TextField } from "@material-ui/core";
import React from "react";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { Provinces, Titles } from "../types";

const m = defineMessages({
  title: {
    id: "ContactForm.Title",
    defaultMessage: "Titre",
  },
  titleMister: {
    id: "ContactForm.Title.Mister",
    defaultMessage: "Monsieur",
  },
  titleMadam: {
    id: "ContactForm.Title.Madam",
    defaultMessage: "Madame",
  },
  titleSir: {
    id: "ContactForm.Title.Sir",
    defaultMessage: "Mr.",
  },
  titleMrs: {
    id: "ContactForm.Title.Mrs",
    defaultMessage: "Mme",
  },
  client1NameLabel: {
    id: "ContactForm.Client1.Name.Label",
    defaultMessage: "Nom Client 1",
  },
  client2NameLabel: {
    id: "ContactForm.Client2.Name.Label",
    defaultMessage: "Nom Client 2",
  },
  companyName: {
    id: "ContactForm.CompanyName",
    defaultMessage: "Nom compagnie",
  },
  street: {
    id: "ContactForm.Street",
    defaultMessage: "Rue",
  },
  city: {
    id: "ContactForm.City",
    defaultMessage: "Ville",
  },
  postalCode: {
    id: "ContactForm.PostalCode",
    defaultMessage: "Code Postal",
  },
  province: {
    id: "ContactForm.Province",
    defaultMessage: "Province",
  },
  provinceAlberta: {
    id: "ContactForm.Province.Alberta",
    defaultMessage: "Alberta",
  },
  provinceBritishColumbia: {
    id: "ContactForm.Province.BritishColumbia",
    defaultMessage: "Colombie-Britannique",
  },
  provincePrinceEdwardIsland: {
    id: "ContactForm.Province.PrinceEdwardIsland",
    defaultMessage: "Île-du-Prince-Édouard",
  },
  provinceManitoba: {
    id: "ContactForm.Province.Manitoba",
    defaultMessage: "Manitoba",
  },
  provinceNewBrunswick: {
    id: "ContactForm.Province.NewBrunswick",
    defaultMessage: "Nouveau-Brunswick",
  },
  provinceNovaScotia: {
    id: "ContactForm.Province.NovaScotia",
    defaultMessage: "Nouvelle-Écosse",
  },
  provinceNunavut: {
    id: "ContactForm.Province.Nunavut",
    defaultMessage: "Nunavut",
  },
  provinceOntario: {
    id: "ContactForm.Province.Ontario",
    defaultMessage: "Ontario",
  },
  provinceQuebec: {
    id: "ContactForm.Province.Quebec",
    defaultMessage: "Québec",
  },
  provinceSaskatchewan: {
    id: "ContactForm.Province.Saskatchewan",
    defaultMessage: "Saskatchewan",
  },
  provinceNewfoundlandLabrador: {
    id: "ContactForm.Province.NewfoundlandLabrador",
    defaultMessage: "Terre-Neuve-et-Labrador",
  },
  provinceNorthwestTerritories: {
    id: "ContactForm.Province.NorthwestTerritories",
    defaultMessage: "Territoires du Nord-Ouest",
  },
  provinceYukon: {
    id: "ContactForm.Province.Yukon",
    defaultMessage: "Yukon",
  },
  telephone: {
    id: "ContactForm.telephone",
    defaultMessage: "Téléphone",
  },
  cellphone: {
    id: "ContactForm.cellphone",
    defaultMessage: "Céllulaire",
  },
  officePhone: {
    id: "ContactForm.officePhone",
    defaultMessage: "Bureau",
  },
  email: {
    id: "ContactForm.email",
    defaultMessage: "Courriel",
  },
});

export const ContactForm: React.FC = () => { 

  const { formatMessage } = useIntl();
  const { control } = useFormContext();

  return (
    <>
      <Grid item xs={3} className="tw-mt-4">
        <FormControl variant="outlined" size="small" className="tw-w-full">
          <InputLabel id="contact1-titre">{formatMessage(m.title)}</InputLabel>
          <Controller
            control={control}
            name="contactInformation.contacts.0.title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                disabled
                labelId="contact2-titre"
                label={formatMessage(m.title)}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
              >
                <MenuItem value={Titles.Mister}>{formatMessage(m.titleMister)}</MenuItem>
                <MenuItem value={Titles.Madam}>{formatMessage(m.titleMadam)}</MenuItem>
                <MenuItem value={Titles.Sir}>{formatMessage(m.titleSir)}</MenuItem>
                <MenuItem value={Titles.Mrs}>{formatMessage(m.titleMrs)}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={9} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.contacts.0.name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contact2Name"
              label={formatMessage(m.client1NameLabel)}
              fullWidth
              autoComplete="client-name"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={3} className="tw-mt-4">
        <FormControl variant="outlined" size="small" className="tw-w-full">
          <InputLabel id="contact2-titre">{formatMessage(m.title)}</InputLabel>
          <Controller
            control={control}
            name="contactInformation.contacts.1.title"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                disabled
                labelId="contact2-titre"
                label={formatMessage(m.title)}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
              >
                <MenuItem value={Titles.Mister}>{formatMessage(m.titleMister)}</MenuItem>
                <MenuItem value={Titles.Madam}>{formatMessage(m.titleMadam)}</MenuItem>
                <MenuItem value={Titles.Sir}>{formatMessage(m.titleSir)}</MenuItem>
                <MenuItem value={Titles.Mrs}>{formatMessage(m.titleMrs)}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={9} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.contacts.1.name"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contact2Name"
              label={formatMessage(m.client2NameLabel)}
              fullWidth
              autoComplete="client-name"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.companyName"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactcompanyname"
              label={formatMessage(m.companyName)}
              fullWidth
              autoComplete="companyname"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={8} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.street"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactstreet"
              label={formatMessage(m.street)}
              fullWidth
              autoComplete="street"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.postalCode"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactpostalCode"
              label={formatMessage(m.postalCode)}
              fullWidth
              autoComplete="postalCode"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.city"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactcity"
              label={formatMessage(m.city)}
              fullWidth
              autoComplete="city"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="tw-mt-4">
        <FormControl variant="outlined" size="small" className="tw-w-full">
          <InputLabel id="client-province">{formatMessage(m.province)}</InputLabel>
          <Controller
            control={control}
            name="contactInformation.province"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                disabled
                labelId="client-province"
                label={formatMessage(m.province)}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
              >
                <MenuItem value={Provinces.Alberta}>{formatMessage(m.provinceAlberta)}</MenuItem>
                <MenuItem value={Provinces.BritishColumbia}>{formatMessage(m.provinceBritishColumbia)}</MenuItem>
                <MenuItem value={Provinces.PrinceEdwardIsland}>{formatMessage(m.provincePrinceEdwardIsland)}</MenuItem>
                <MenuItem value={Provinces.Manitoba}>{formatMessage(m.provinceManitoba)}</MenuItem>
                <MenuItem value={Provinces.NewBrunswick}>{formatMessage(m.provinceNewBrunswick)}</MenuItem>
                <MenuItem value={Provinces.NovaScotia}>{formatMessage(m.provinceNovaScotia)}</MenuItem>
                <MenuItem value={Provinces.Nunavut}>{formatMessage(m.provinceNunavut)}</MenuItem>
                <MenuItem value={Provinces.Ontario}>{formatMessage(m.provinceOntario)}</MenuItem>
                <MenuItem value={Provinces.Quebec}>{formatMessage(m.provinceQuebec)}</MenuItem>
                <MenuItem value={Provinces.Saskatchewan}>{formatMessage(m.provinceSaskatchewan)}</MenuItem>
                <MenuItem value={Provinces.NewfoundlandLabrador}>{formatMessage(m.provinceNewfoundlandLabrador)}</MenuItem>
                <MenuItem value={Provinces.NorthwestTerritories}>{formatMessage(m.provinceNorthwestTerritories)}</MenuItem>
                <MenuItem value={Provinces.Yukon}>{formatMessage(m.provinceYukon)}</MenuItem>
              </Select>
            )}
          />         
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={4} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.telephone"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contacttelephone"
              label={formatMessage(m.telephone)}
              fullWidth
              autoComplete="telephone"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.cellphone"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactcellphone"
              label={formatMessage(m.cellphone)}
              fullWidth
              autoComplete="cellphone"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className='tw-mt-4'>
        <Controller
          control={control}
          name="contactInformation.officephone"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactofficephone"
              label={formatMessage(m.officePhone)}
              fullWidth
              autoComplete="officephone"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} className="tw-mt-4">
        <Controller
          control={control}
          name="contactInformation.email"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="contactemail"
              label={formatMessage(m.email)}
              fullWidth
              autoComplete="email"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
    </>
  );
};