import { Box, Card, CardActionArea, CardContent, IconButton, Typography } from "@material-ui/core";
import React from "react";
import { Link } from "react-router-dom";
import { PropositionSearchResult } from "../types";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";

export interface PropositionSearchCardProps {
  proposition: PropositionSearchResult;
}

export const PropositionSearchCard: React.FC<PropositionSearchCardProps> = (
  props: PropositionSearchCardProps
) => {

  return (
    <Card className='tw-my-2'>
      <CardActionArea component={Link} to={`/propositions/${props.proposition.opportunityId}`}>
        <CardContent style={{paddingBottom:16}}>
          <Box display='flex' justifyContent='space-between' alignItems='center'>
            <Box>
              <Typography gutterBottom variant='h5' component='h2'>
                {props.proposition.propositionNumber}
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                {props.proposition.client1}
                {props.proposition.client2 ? ` & ${props.proposition.client2}` : ""}
              </Typography>
              <Typography variant='body2' color='textSecondary' component='p'>
                {props.proposition.workAddress}
              </Typography>
            </Box>
            <Box>
              <IconButton color="primary">
                <ChevronRightIcon />
              </IconButton>
            </Box>
          </Box>
        </CardContent>
      </CardActionArea>
    </Card>
  );
};
