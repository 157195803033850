import * as actionsTypes from "./actionTypes";
import { ApplicationActionTypes } from ".";
import { SupportedLanguage } from "./types";

export function startApplication(): ApplicationActionTypes {
  return {
    type: actionsTypes.START_APPLICATION
  };
}

export function startApplicationCompleted(language: SupportedLanguage): ApplicationActionTypes {
  return {
    type: actionsTypes.APPLICATION_STARTED,
    payload: language
  };
}
