import { defaultErrorHandler } from "api";
import { useEffect, useState } from "react";
import { PropositionsApi } from "../api";
import { Proposition } from "../types";

interface Output {
    proposition: Proposition | undefined;
    isFetched: boolean;
}
  
export const useProposition = (propositionId: string) : Output => {

  const [proposition, setProposition] = useState<Proposition>();
  const [isFetched, setIsFetched] = useState<boolean>(false);

  useEffect(() => {
    PropositionsApi.fetchProposition(propositionId)
      .then((fetchedProposition) => {
        setProposition(fetchedProposition);
        setIsFetched(true);
      })
      .catch((error) => {
        setProposition(undefined);
        setIsFetched(false);
        defaultErrorHandler(error);
      });
  }, []);

  return { proposition, isFetched };
};