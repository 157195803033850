import React from "react";
import { TopBar } from "./TopBar";
import { Grid } from "@material-ui/core";
import { Footer } from "./Footer";

export interface PageLayoutProps {
  children?: React.ReactNode;
}

export const PageLayout: React.FC<PageLayoutProps> = (props : PageLayoutProps) => {  

  return (
    <Grid direction="column" container style={{paddingBottom:84}} className="tw-min-h-screen tw-bg-gray-50 tw-relative">
      <TopBar />
      {props.children}
      <Footer />
    </Grid>
  );
};