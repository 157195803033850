import React from "react";
import { PublicClientApplication } from "@azure/msal-browser";
import { msalConfig } from "authConfig";
import { createBrowserHistory } from "history";
import ReactDOM from "react-dom";
import { App } from "./App";
import "./index.scss";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { AppInsightsContext, AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js";
import { ConnectedRouter } from "connected-react-router";
import { CssBaseline } from "@material-ui/core";
import { configureStore } from "store";
import { MsalProvider } from "@azure/msal-react";
import { configureApplicationInsights } from "./appInsights";
import { ConnectedIntlProvider } from "components/ConnectedIntlProvider";
import { ApplicationError } from "modules/errors/views/ApplicationError";
import { SnackbarProvider } from "notistack";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

export const history = createBrowserHistory();
export const msalInstance = new PublicClientApplication(msalConfig);
const store = configureStore(history);
const reactPlugin = configureApplicationInsights(history);

ReactDOM.render(
  <Provider store={store}>
    <AppInsightsContext.Provider value={reactPlugin}>
      <MsalProvider instance={msalInstance}>
        <ConnectedRouter history={history}>
          <ConnectedIntlProvider>
            <CssBaseline>
              <AppInsightsErrorBoundary appInsights={reactPlugin} onError={() => <ApplicationError />}>
                <SnackbarProvider maxSnack={3}>
                  <App />
                </SnackbarProvider>
              </AppInsightsErrorBoundary>
            </CssBaseline>
          </ConnectedIntlProvider>
        </ConnectedRouter>
      </MsalProvider>
    </AppInsightsContext.Provider>
  </Provider>,
  document.getElementById("root"),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.register();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
