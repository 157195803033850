import React from "react";
import { IntlProvider } from "react-intl";
import dayjs from "dayjs";
import { defaultApi } from "api";
import "dayjs/locale/fr-ca";
import { useSelector } from "react-redux";
import { RootState } from "store";

const translations = {
  "en-CA": require("translations/en-CA.json"),
};

interface Props {
  children?: React.ReactNode;
}

export const ConnectedIntlProvider: React.FC<Props> = ({ children } : Props) => {
  const language = useSelector((state: RootState) => state.application.language);
  const defaultLocale = "fr-CA";
  const messages =
    language === defaultLocale ? undefined : translations[language];

  defaultApi.defaults.headers.common["accept-language"] = language;
  dayjs.locale(language.toLocaleLowerCase());
  
  return (
    <IntlProvider
      locale={language}
      messages={messages}
      defaultLocale={defaultLocale}
    >
      {children}
    </IntlProvider>
  );
};
