import { InteractionRequiredAuthError } from "@azure/msal-browser";
import { silentRequest } from "authConfig";
import axios, { AxiosRequestConfig, AxiosResponse, CancelTokenSource } from "axios";
import { msalInstance } from "index";
import { ApiError } from "./apiError";

export const defaultApi = axios.create({
  responseType: "json",
});

export const authorizationConfig = async(config?: AxiosRequestConfig): Promise<AxiosRequestConfig> => {
  const activeAccount = msalInstance.getActiveAccount();
  const accounts = msalInstance.getAllAccounts();

  if (!config) {
    config = axios.defaults;
  }

  if (activeAccount || accounts.length > 0) {
    const request = {
      ...silentRequest,
      account: activeAccount || accounts[0]
    };
    
    try
    {
      const authResult = await msalInstance.acquireTokenSilent(request);
      config.headers["Authorization"] = `Bearer ${authResult.accessToken}`;
      return config;
    }
    catch(error) {
      if (error instanceof InteractionRequiredAuthError) {
        const redirectRequest = {
          scopes: request.scopes,
          loginHint: request.account.username
        };
        msalInstance.acquireTokenRedirect(redirectRequest);
      }
    }
  }
  return config;
};

defaultApi.interceptors.request.use(async config => {
  return await authorizationConfig(config);
});

defaultApi.interceptors.response.use(
  (response) => response,
  (error) => {
    const { status, data } = error.response;
    return Promise.reject(
      new ApiError(
        status,
        data ? data.userMessage : error.message,
        data?.debugMessage,
        data?.debugDetails
      )
    );
  }
);

const responseBody = (response: AxiosResponse) => response.data;

export const requests = {
  get: <T>(url: string, config?: AxiosRequestConfig, cancellationToken?: CancelTokenSource ):Promise<T> => defaultApi.get(url, { ...config, cancelToken: cancellationToken?.token}).then(responseBody),
  post: <T>(url: string, body: unknown):Promise<T> => defaultApi.post(url, body).then(responseBody),
  put: <T>(url: string, body: unknown):Promise<T> => defaultApi.put(url, body).then(responseBody),
  patch: <T>(url: string, body: unknown):Promise<T> => defaultApi.patch(url, body).then(responseBody),
  del: <T>(url: string):Promise<T> => defaultApi.delete(url).then(responseBody),
};