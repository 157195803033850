import React from "react";
import { TextField, Grid, Tabs, Tab, makeStyles, Switch, FormControlLabel, FormControl } from "@material-ui/core";
import { Controller, useFormContext } from "react-hook-form";
import { Autocomplete, TabContext, TabPanel } from "@material-ui/lab";
import { useFetchClauseCategories } from "../hooks/useFetchClauseCategories";
import { useWorkTypes } from "modules/quote/hooks/useWorkTypes";

const useStyles = makeStyles((theme) => ({
  tabPanel: {
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: theme.palette.primary.main,
    padding: theme.spacing(2)
  },
}));

export interface ClauseFormProps {
  isSaving: boolean;
}

export const ClauseForm: React.FC<ClauseFormProps> = (props:ClauseFormProps) => {
  const { control, formState } = useFormContext();
  const [tabValue, setTabValue] = React.useState("fr");
  const classes = useStyles();
  const {clauseCategories, isFetched: clauseCategoriesFetched} = useFetchClauseCategories();
  const {workTypes, isFetched: workTypesFetched} = useWorkTypes();

  const { isSaving } = props;

  const handleTabChange = (_event: unknown, value: string) => {
    setTabValue(value);
  };
  
  return (
    <form>
      <Grid container direction="column" spacing={2}>
        <Grid item>
          <TabContext value={tabValue}>
            <Tabs
              value={tabValue}
              onChange={handleTabChange}
              indicatorColor="primary"
              textColor="primary"
            >
              <Tab label="Francais" value="fr"/>
              <Tab label="Anglais" value="en"/>
            </Tabs>
            <TabPanel value="fr" tabIndex={0} className={classes.tabPanel}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Controller
                    name="nameI18n.fr"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField 
                        disabled={isSaving}
                        variant="outlined"
                        fullWidth 
                        onChange={onChange}
                        value={value} 
                        label={"Nom"}
                        required
                        error={Boolean(formState.errors?.nameI18n?.fr)}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="textI18n.fr"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField 
                        disabled={isSaving}  
                        variant="outlined"
                        fullWidth
                        multiline
                        onChange={onChange}
                        value={value} 
                        label={"Text"} 
                        required
                        error={Boolean(formState.errors?.textI18n?.fr)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value="en" tabIndex={1} className={classes.tabPanel}>
              <Grid container direction="column" spacing={2}>
                <Grid item>
                  <Controller
                    name="nameI18n.en"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField 
                        disabled={isSaving}
                        variant="outlined" 
                        fullWidth
                        onChange={onChange}
                        value={value}
                        label={"Nom"} 
                        required
                        error={Boolean(formState.errors?.nameI18n?.en)}
                      />
                    )}
                  />
                </Grid>
                <Grid item>
                  <Controller
                    name="textI18n.en"
                    rules={{ required: true }}
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <TextField 
                        disabled={isSaving}
                        variant="outlined" 
                        fullWidth 
                        multiline
                        onChange={onChange} 
                        value={value}
                        label={"Text"}
                        required
                        error={Boolean(formState.errors?.textI18n?.en)}
                      />
                    )}
                  />
                </Grid>
              </Grid>
            </TabPanel>
          </TabContext>
        </Grid>
        <Grid item container spacing={2} alignItems="center" justify="space-between">
          <Grid item>
            <Controller
              name="order"
              rules={{ required: true, min: 1 }}
              control={control}
              render={({ field: { onChange, value } }) => (
                <TextField 
                  disabled={isSaving}
                  type="number"
                  variant="outlined"
                  onChange={onChange}
                  value={value}
                  label={"Ordre"}
                  required
                  error={Boolean(formState.errors?.order)}
                />
              )}
            />
          </Grid>
          <Grid item>
            <FormControlLabel control={
              <Controller
                name="canChooseResponsible"
                control={control}
                render={({field}) => (
                  <Switch
                    disabled={isSaving}
                    {...field}
                    onChange={(e,v)=> {field.onChange(v);}}
                    checked={field.value}
                    color="primary"
                  />
                )}
              />} label="Responsabilité selectionnable" />
          </Grid>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>                 
            <Controller
              control={control}
              name="clauseCategory"
              rules={{ required: true }}
              render={renderProps => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { onChange, ...rest } = renderProps.field;
                return(
                  <Autocomplete
                    loading={clauseCategoriesFetched}
                    disabled={isSaving || !clauseCategoriesFetched}
                    options={clauseCategories}
                    onChange={(e, data) => onChange(data)}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    fullWidth
                    {...rest}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Categorie"
                        placeholder="Selectionner un categorie"
                        error={Boolean(formState.errors?.clauseCategory)}
                      />
                    )}
                  />
                );}
              }
            />
          </FormControl>
        </Grid>
        <Grid item>
          <FormControl variant="outlined" fullWidth>                 
            <Controller
              control={control}
              name="workTypes"
              rules={{ required: true }}
              render={renderProps => {
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const { onChange, ...rest } = renderProps.field;
                return(
                  <Autocomplete
                    multiple
                    loading={workTypesFetched}
                    disabled={isSaving || !workTypesFetched}
                    options={workTypes}
                    onChange={(e, data) => onChange(data)}
                    getOptionLabel={(option) => option.name}
                    getOptionSelected={(option, value) => option.id === value.id}
                    filterSelectedOptions
                    {...rest}
                    renderInput={(params) => (
                      <TextField
                        required
                        {...params}
                        variant="outlined"
                        label="Type de travaux"
                        placeholder="Selectionner des types de travaux"
                        error={Boolean(formState.errors?.workTypes)}
                      />
                    )}
                  />
                );}
              }
            />
          </FormControl>
        </Grid>
      </Grid>
    </form>
  );
};