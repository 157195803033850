import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import { MSButton } from "components/MSButton";
import { useMsal } from "@azure/msal-react";
import { loginRequest } from "authConfig";
import { Box, Button, Container, Divider, Grid, Typography } from "@material-ui/core";
import { registerDemoUser } from "modules/account/actions";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "store";
import { useHistory } from "react-router";

const m = defineMessages({
  title: {
    id: "LoginPage.Title",
    defaultMessage: "Générateur de soumission",
  },
  subtitle: {
    id: "LoginPage.Subtitle",
    defaultMessage: "Bienvenue",
  },
  message: {
    id: "LoginPage.Message",
    defaultMessage: "Veuillez-vous identifier pour accéder a l'application",
  },
});

export const ExternalLoginPage: React.FC = () => {
  const { formatMessage } = useIntl();
  const { instance } = useMsal();
  const dispatch = useDispatch();
  const isAuthenticated = useSelector((state: RootState) => state.account.isAuthenticated);
  const history = useHistory();


  useEffect(() => {
    if(isAuthenticated) {
      history.push("/");
    }
  }, [isAuthenticated]);

  const handleMSOnClick = () => {
    instance.loginRedirect(loginRequest);
  };

  const handleDemoUserLoginClick = () => {
    dispatch(registerDemoUser());
  };

  return (
    <Box className="tw-flex tw-flex-col tw-min-h-screen">
      <Box className="tw-flex tw-flex-grow tw-bg-primary">
        <Container maxWidth="sm" className="tw-flex tw-flex-col">
          <Grid container direction="column" justify="center" alignItems="center" className="tw-flex tw-flex-grow">
            <img src='/images/logo_w.png' alt="Polar Breeze Ltd" style={{maxWidth:200}} />
          </Grid>
          <Grid container direction="column" justify="center" alignItems="center" className="tw-flex tw-pb-10">
            <img src='/images/quote-generator.png' alt="camera" width={80} className="tw-mb-4"/>
            <Typography variant="h4" className="tw-text-white tw-text-center" component="h1">{formatMessage(m.title)}</Typography>
          </Grid>
        </Container>
      </Box>
      <Box>
        <Container maxWidth="sm">
          <Grid container direction="column" alignItems="center" className="tw-flex tw-py-10">
            <Typography variant="h4" component="h2">{formatMessage(m.subtitle)}</Typography>
            <Typography paragraph className="tw-py-10 tw-text-center">{formatMessage(m.message)}</Typography>
            <Box className="tw-flex tw-justify-center">
              <MSButton onClick={handleMSOnClick} />
            </Box>
            <Typography variant="h6" className="tw-text-center tw-my-2">OU</Typography>
            <Box className="tw-flex tw-justify-center">
              <Button variant="contained" color="primary" onClick={handleDemoUserLoginClick}>Utilisateur Demo</Button>
            </Box>
          </Grid>
        </Container>
      </Box>
    </Box>
  );
};
