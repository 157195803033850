import "./MSButton.scss";

import { ReactComponent as MSLogo } from "./microsoft.svg";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

const m = defineMessages({
  text: {
    id: "msbutton.text",
    defaultMessage: "Se connecter avec Microsoft",
  },
});

export interface MSButtonProps {
  onClick: React.MouseEventHandler<HTMLButtonElement>;
}

export const MSButton: React.FC<MSButtonProps> = (props: MSButtonProps) => {
  const { formatMessage } = useIntl();

  return (
    <button className="ms-button" onClick={props.onClick}>
      <MSLogo /> {formatMessage(m.text)}
    </button>
  );
};
