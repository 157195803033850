import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ErrorPage } from "../components/ErrorPage";
import { ReactComponent as AccessDeniedIcon } from "icons/undraw_access_denied.svg";

const m = defineMessages({
  title: {
    id: "Errors.ForbiddenError.Title",
    defaultMessage: "Oups...on dirait que tu n'a pas accès",
  },
  message: {
    id: "Errors.ForbiddenError.Message",
    defaultMessage: "Réessayez avec un autre compte.",
  }
});

export const ForbiddenError: React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ErrorPage
      errorCode={403}
      title={formatMessage(m.title)}
      message={formatMessage(m.message)}
      svgImage={<AccessDeniedIcon />}
    />
  );
};
