import { Divider, FormControlLabel, Input, Radio, RadioGroup, Switch, Theme, Tooltip, withStyles } from "@material-ui/core";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import { DragHandleIcon } from "icons/DragHandleIcon";
import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { Controller, useFormContext } from "react-hook-form";
import { defineMessages, useIntl } from "react-intl";
import { ClauseValueWithIndex } from "../types";
import { FormValues } from "./QuoteGeneratorForm";

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300
  },
}))(Tooltip);

const m = defineMessages({
  responsibilityCompany: {
    id: "QuoteClause.ResponsibilityCompany",
    defaultMessage: "Compagnie",
  },
  responsibilityClient: {
    id: "QuoteClause.ResponsibilityClient",
    defaultMessage: "Client",
  },
});

export interface QuoteClauseItemProps {
    index: number,
    clauseNumber: number,
    sectionNumber: number,
    clause: ClauseValueWithIndex,
    grid: number,
}

export const QuoteClauseItem: React.FC<QuoteClauseItemProps> = (props: QuoteClauseItemProps) => { 

  const { formatMessage } = useIntl();
  const { control, getValues, formState } = useFormContext<FormValues>();
  const [openTooltip, setOpenTooltip] = React.useState(false);

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOnClickTooltip = () => {
    setOpenTooltip(!openTooltip);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const getItemStyle = (isDragging: boolean, draggableStyle: any) => ({
    userSelect: "none",
    padding: props.grid,
    margin: `${props.grid}px 0 0`,
    background: props.clause.isEnable ? (isDragging ? "#24C5F0" : "white") : "#DFDFDF",
    border: props.clause.isEnable && formState.errors.workDescription?.clauses?.[props.clause.index] ? "2px solid #f44336" : "",
    ...draggableStyle
  });

  const validateClauseVariable = (variableIndex: number) => {
    const clause = getValues(`workDescription.clauses.${props.clause.index}`);
    if(clause.isEnable && !clause.variables[variableIndex])
    {
      return "Variable is required";
    }
    return true;
  };

  const renderClauseName = () => {
    const nameTemplate = props.clause.name;
    const regex = new RegExp(/({\d})/);
    const splits = nameTemplate.split(regex);
    let variableCount = 0;
    return (
      splits.map(s => {
        if(regex.test(s)){
          const variableIndex = variableCount++;
          const value = getValues(`workDescription.clauses.${props.clause.index}.variables.${variableIndex}`);
          return <Controller
            name={`workDescription.clauses.${props.clause.index}.variables.${variableIndex}`}
            control={control}
            rules={{validate: () => validateClauseVariable(variableIndex)}}
            defaultValue={getValues(`workDescription.clauses.${props.clause.index}.variables.${variableIndex}`)}
            render={({field}) => (
              <Input 
                key={`${props.clause.id}.${variableCount}`}
                {...field}
                defaultValue={value}
                error={Boolean(formState.errors.workDescription?.clauses?.[props.clause.index]?.variables?.[variableIndex])}
                inputProps={{ "aria-label": "variable", "style": {textAlign: "center"}}}
                style={{maxWidth:100}}
                disabled={!props.clause.isEnable}
              />
            )}
          />;
        }
        return s;
      })
    );
  };

  const renderClauseDescription = () => {
    if(props.clause.description && props.clause.description.length > 0){
      return props.clause.description;
    }
    else{
      const variables = props.clause.variables || [];
      return props.clause.text.replace(/\{\d\}/mg, (match) => variables[parseInt(match.slice(1,-1))] || "___");
    }
  };

  const renderClauseContent = () => {
    return (
      <>
        <Controller
          name={`workDescription.clauses.${props.clause.index}.isEnable`}
          control={control}
          
          defaultValue={getValues(`workDescription.clauses.${props.clause.index}.isEnable`)}
          render={({field}) => (
            <Switch
              {...field}
              onChange={(e,v)=> {field.onChange(v);}}
              checked={field.value}
              color="primary"
            />
          )}
        />
        <Divider orientation="vertical" flexItem />
        <div className="tw-flex tw-items-center tw-flex-grow">
          <div className="tw-mx-4">
            {props.clause.isEnable && `${props.sectionNumber}.${props.clauseNumber}`} {renderClauseName()}
          </div>
          <CustomTooltip title={renderClauseDescription()} placement="top" arrow open={openTooltip} onOpen={handleOpenTooltip} onClose={handleCloseTooltip}>
            <HelpRoundedIcon fontSize="small" onClick={handleOnClickTooltip} className="tw-cursor-pointer" />
          </CustomTooltip>
        </div>

        {props.clause.canChooseResponsible && (
          <Controller
            name={`workDescription.clauses.${props.clause.index}.responsible`}
            control={control}
            defaultValue={getValues(`workDescription.clauses.${props.clause.index}.responsible`)}
            render={({field}) => (
              <RadioGroup
                aria-label="responsibility" 
                {...field}
                row
              >
                <FormControlLabel value="company" control={<Radio size="small" />} label={formatMessage(m.responsibilityCompany)} disabled={!props.clause.isEnable}/>
                <FormControlLabel value="client" control={<Radio size="small" />} label={formatMessage(m.responsibilityClient)} disabled={!props.clause.isEnable} />
              </RadioGroup>
            )}
          />
        )}
        <DragHandleIcon className={props.clause.isEnable ? "tw-cursor-move" : ""}/>
      </>
    );
  };

  return (
    <>
      <Draggable key={props.clause.id} draggableId={props.clause.id.toString()} index={props.index} isDragDisabled={!props.clause.isEnable}>
        {(provided, snapshot) => (
          <div 
            className="tw-flex tw-items-center tw-w-full tw-cursor-default"
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={getItemStyle(
              snapshot.isDragging,
              provided.draggableProps.style
            )}
          >
            {renderClauseContent()}
          </div>
        )}
      </Draggable>
    </>
  );
};