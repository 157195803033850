import { Card, CardActions, CardContent, Grid, IconButton, Theme, Tooltip, withStyles } from "@material-ui/core";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import HelpRoundedIcon from "@material-ui/icons/HelpRounded";
import React from "react";
import { Clause } from "modules/quote/types";
import { UpdateClauseFormDialog } from "./UpdateClauseFormDialog";
import { DeleteDialog } from "./DeleteDialog";
import { ClausesApi } from "../api";
import { ApiError, defaultErrorHandler } from "api";

const CustomTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    fontSize: theme.typography.pxToRem(14),
    fontWeight: 300
  },
}))(Tooltip);

export interface ClauseCardProps {
  clause: Clause;
  onClauseSaved: () => void;
  onClauseDeleted: () => void;
}

export const ClauseCard: React.FC<ClauseCardProps> = (props:ClauseCardProps) => {
  const [openTooltip, setOpenTooltip] = React.useState(false);
  const [openUpdateDialog, setOpenUpdateDialog] = React.useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = React.useState(false);

  const handleOpenTooltip = () => {
    setOpenTooltip(true);
  };

  const handleCloseTooltip = () => {
    setOpenTooltip(false);
  };

  const handleOnClickTooltip = () => {
    setOpenTooltip(!openTooltip);
  };

  const handleDialogClickOpen = () => {
    setOpenUpdateDialog(true);
  };

  const handleDialogClose = () => {
    setOpenUpdateDialog(false);
  };

  const handleOnSaved = () => {
    setOpenUpdateDialog(false);
    props.onClauseSaved();
  };

  const handleDeleteDialogClickOpen = () => {
    setOpenDeleteDialog(true);
  };

  
  const handleDeleteDialogClose = () => {
    setOpenDeleteDialog(false);
  };

  const handleOnDelete = async () => {
    try {
      await ClausesApi.delete(props.clause.id);
      props.onClauseDeleted();
      setOpenDeleteDialog(false);
    } catch (error) {
      defaultErrorHandler(error as ApiError);
    }
  };

  return (
    <>
      <Card className="tw-my-2">
        <Grid container direction="row" alignItems="center" className="tw-flex-nowrap">
          <CardContent className="tw-flex tw-flex-grow tw-p-2">
            <div className="tw-flex tw-items-center">
              <div className="tw-mx-4">{props.clause.name}</div>
              <CustomTooltip title={props.clause.text} placement="top" arrow open={openTooltip} onOpen={handleOpenTooltip} onClose={handleCloseTooltip}>
                <HelpRoundedIcon fontSize="small" onClick={handleOnClickTooltip} className="tw-cursor-pointer" />
              </CustomTooltip>
            </div>
          </CardContent>
          <CardActions>
            <IconButton color="primary" size="small" onClick={handleDialogClickOpen}>
              <EditIcon />
            </IconButton>
            <IconButton size="small">
              <DeleteIcon onClick={handleDeleteDialogClickOpen}/>
            </IconButton>
          </CardActions>
        </Grid>
        {openUpdateDialog && <UpdateClauseFormDialog id={props.clause.id} isOpen={openUpdateDialog} handleClose={handleDialogClose} onSaved={handleOnSaved} />}
        {openDeleteDialog && <DeleteDialog title="Supprimer la clause" onDelete={handleOnDelete} isOpen={openDeleteDialog} handleClose={handleDeleteDialogClose} />}
      </Card>
    </>        
  );
};