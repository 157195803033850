import React, { ChangeEvent, useRef, useState } from "react";
import { PageLayout } from "components/Layout/PageLayout";
import { Box, Container, debounce, InputBase, Paper } from "@material-ui/core";
import SearchIcon from "@material-ui/icons/Search";
import { defineMessages, useIntl } from "react-intl";
import { PropositionSearchResults } from "../components/PropositionSearchResults";

const m = defineMessages({
  searchPlaceholder: {
    id: "QuoteSearch.SearchPlaceholder",
    defaultMessage: "Rechercher une proposition...",
  }
});

export const QuoteSearch: React.FC = () => {  
  const { formatMessage } = useIntl();
  const [query, setQuery] = useState<string>("");

  const delayedQuery = useRef(debounce((q: string) => setQuery(q), 800)).current;

  const onSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
    delayedQuery(event.currentTarget.value);
  };

  return (
    <PageLayout>
      <Box className="tw-bg-secondary">
        <Container maxWidth="lg">
          <Paper className="tw-px-2 tw-py-1 tw-flex tw-justify-between tw-items-center tw-my-10">
            <InputBase
              className="tw-flex tw-flex-grow"
              placeholder={formatMessage(m.searchPlaceholder)}
              inputProps={{ "aria-label": `${formatMessage(m.searchPlaceholder)}}` }}
              onChange={onSearchChange}
            />
            <SearchIcon />
          </Paper>
        </Container>
      </Box>
      <PropositionSearchResults query={query} />
    </PageLayout>
  );
};
