import { Box, makeStyles, Toolbar, Typography } from "@material-ui/core";
import React from "react";
import { defineMessages, useIntl } from "react-intl";

const useStyles = makeStyles((theme) => ({
  footer: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));


const m = defineMessages({
  version: {
    id: "Footer.Version",
    defaultMessage: "Version",
  }
});

export const Footer: React.FC = () => {  
  const { formatMessage } = useIntl(); 
  const classes = useStyles();

  return (
    <Box className={"tw-absolute tw-w-full tw-bottom-0 tw-bg-white tw-border-t tw-border-gray-200 " + classes.footer}>
      <Toolbar style={{minHeight:"inherit"}} className="tw-py-2">
        <Typography variant="body2" color="textSecondary" component="p">{formatMessage(m.version)} {process.env.REACT_APP_VERSION}</Typography>
      </Toolbar>
    </Box>
  );
};