import { createMuiTheme } from "@material-ui/core/styles";

export const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#80C8FF",
      contrastText: "#fff"
    },
    secondary: {
      main: "#4B2480",
      contrastText: "#fff"
    },
  },
});