import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { ErrorPage } from "../components/ErrorPage";
import { ReactComponent as SecurityIcon } from "icons/undraw_security.svg";

const m = defineMessages({
  title: {
    id: "Errors.UnauthorizedError.Title",
    defaultMessage: "Oups...tu n'es pas autorisé",
  },
  message: {
    id: "Errors.UnauthorizedError.Message",
    defaultMessage: "Réessayez avec un autre compte.",
  }
});

export const UnauthorizedError : React.FC = () => {
  const { formatMessage } = useIntl();

  return (
    <ErrorPage
      errorCode={401}
      title={formatMessage(m.title)}
      message={formatMessage(m.message)}
      svgImage={<SecurityIcon />}
    />
  );
};
