import { Box, Drawer, List, ListItem, ListItemText, makeStyles, Toolbar } from "@material-ui/core";
import { PageLayout } from "components/Layout/PageLayout";
import React from "react";
import { NavLink } from "react-router-dom";

const useStyles = makeStyles(() => ({
  drawer: {
    width: 250,
    flexShrink: 0,
  },
  drawerPaper: {
    width: 250,
  },
  content: {
    flexGrow: 1,
    marginLeft: 250,
  },
}));

export interface PageLayoutProps {
  children?: React.ReactNode;
}

export const AdminPageLayout: React.FC<PageLayoutProps> = (props: PageLayoutProps) => {
  const classes = useStyles();

  return (
    <PageLayout>
      <Drawer
        variant="permanent"
        className={classes.drawer}
        classes={{
          paper: classes.drawerPaper
        }}
      >
        <Toolbar />
        <List>
          <ListItem button component={NavLink} to="/admin" exact activeClassName="Mui-selected">
            <ListItemText primary={"Clauses"}/>
          </ListItem>
          <ListItem button component={NavLink} to="/admin/clausecategories" activeClassName="Mui-selected">
            <ListItemText primary={"Clause catégories"} />
          </ListItem>
          <ListItem button component={NavLink} to="/admin/guarantees" activeClassName="Mui-selected">
            <ListItemText primary={"Garanties"} />
          </ListItem>
          <ListItem button component={NavLink} to="/admin/worktypes" activeClassName="Mui-selected">
            <ListItemText primary={"Types de travaux"} />
          </ListItem>
        </List>
      </Drawer>
      <Box className={classes.content}>
        {props.children}
      </Box>
    </PageLayout>
  );
};