import { requests } from "api";
import { CancelTokenSource } from "axios";
import { Clause, ClauseCategory } from "modules/quote/types";
import { Guarantee } from "./types";

const clauseCategoriesApiBaseUrl = "/api/clausecategories";
const clausesApiBaseUrl = "/api/clauses";
const guaranteesApiBaseUrl = "/api/guarantees";

export interface CreateClauseCommand {
  order: number;
  canChooseResponsible: boolean;
  nameI18n: Record<string, string>;
  textI18n: Record<string, string>;
  clauseCategoryId: number;
  worktypesIds: number[];
}

export type UpdateClauseCommand = CreateClauseCommand;

export const ClauseCategoriesApi = {
  get: (id: number): Promise<ClauseCategory> => requests.get(`${clauseCategoriesApiBaseUrl}/${id}`),
  getAll: (cancellationToken: CancelTokenSource): Promise<ClauseCategory[]> => requests.get(`${clauseCategoriesApiBaseUrl}`, {}, cancellationToken),
};

export const ClausesApi = {
  get: (id: number, cancellationToken: CancelTokenSource): Promise<Clause> => requests.get(`${clausesApiBaseUrl}/${id}`, {}, cancellationToken),
  getAll: (cancellationToken: CancelTokenSource): Promise<Clause[]> => requests.get(`${clausesApiBaseUrl}`, {}, cancellationToken),
  update: (id: number, updateClauseCommand: UpdateClauseCommand): Promise<void> => requests.patch(`${clausesApiBaseUrl}/${id}`, updateClauseCommand),
  create: (createClauseCommand: CreateClauseCommand): Promise<void> => requests.post(`${clausesApiBaseUrl}`, createClauseCommand),
  delete: (id: number): Promise<void> => requests.del(`${clausesApiBaseUrl}/${id}`)
};

export const GuaranteesApi = {
  get: (id: number): Promise<Guarantee> => requests.get(`${guaranteesApiBaseUrl}/${id}`),
  getAll: (cancellationToken: CancelTokenSource): Promise<Guarantee[]> => requests.get(`${guaranteesApiBaseUrl}`, {}, cancellationToken),
};
