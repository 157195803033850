import { connectRouter, routerMiddleware } from "connected-react-router";
import createSagaMiddleware from "redux-saga";
import { History } from "history";
import {
  combineReducers, Store, createStore, applyMiddleware,
} from "redux";
import { composeWithDevTools } from "redux-devtools-extension";
import { RootState } from "./types";
import rootSaga from "./sagas";
import { applicationReducer } from "modules/application";
import { accountReducer } from "modules/account";

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export const rootReducer = (history: History) => combineReducers({
  router: connectRouter(history),
  account: accountReducer,
  application: applicationReducer,
});

export const configureStore = (history: History): Store<RootState> => {
  const sagaMiddleware = createSagaMiddleware();

  const middlewares = [routerMiddleware(history), sagaMiddleware];
  const middlewareEnhancer = applyMiddleware(...middlewares);

  const enhancers = [middlewareEnhancer];
  const composedEnhancers = composeWithDevTools(...enhancers);


  const store = createStore(
    rootReducer(history),
    {},
    composedEnhancers
  );

  sagaMiddleware.run(rootSaga);

  return store as Store<RootState>;
};
