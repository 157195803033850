import { FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from "@material-ui/core";
import DayjsUtils from "@date-io/dayjs";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { Controller, useFormContext } from "react-hook-form";
import { Provinces } from "../types";
import { useFormValidations } from "../hooks/useFormValidations";

const m = defineMessages({
  propositionNumber: {
    id: "WorkForm.PropositionNumber",
    defaultMessage: "No Proposition",
  },
  date: {
    id: "WorkForm.Date",
    defaultMessage: "Date",
  },
  language: {
    id: "WorkForm.Language",
    defaultMessage: "Langue",
  },
  languageFrench: {
    id: "WorkForm.Language.French",
    defaultMessage: "Français",
  },
  languageEnglish: {
    id: "WorkForm.Language.English",
    defaultMessage: "Anglais",
  },
  street: {
    id: "WorkForm.Street",
    defaultMessage: "Rue",
  },
  postalCode: {
    id: "WorkForm.PostalCode",
    defaultMessage: "Code Postal",
  },
  city: {
    id: "WorkForm.City",
    defaultMessage: "Ville",
  },
  province: {
    id: "WorkForm.Province",
    defaultMessage: "Province",
  },
  provinceAlberta: {
    id: "WorkForm.Province.Alberta",
    defaultMessage: "Alberta",
  },
  provinceBritishColumbia: {
    id: "WorkForm.Province.BritishColumbia",
    defaultMessage: "Colombie-Britannique",
  },
  provincePrinceEdwardIsland: {
    id: "WorkForm.Province.PrinceEdwardIsland",
    defaultMessage: "Île-du-Prince-Édouard",
  },
  provinceManitoba: {
    id: "WorkForm.Province.Manitoba",
    defaultMessage: "Manitoba",
  },
  provinceNewBrunswick: {
    id: "WorkForm.Province.NewBrunswick",
    defaultMessage: "Nouveau-Brunswick",
  },
  provinceNovaScotia: {
    id: "WorkForm.Province.NovaScotia",
    defaultMessage: "Nouvelle-Écosse",
  },
  provinceNunavut: {
    id: "WorkForm.Province.Nunavut",
    defaultMessage: "Nunavut",
  },
  provinceOntario: {
    id: "WorkForm.Province.Ontario",
    defaultMessage: "Ontario",
  },
  provinceQuebec: {
    id: "WorkForm.Province.Quebec",
    defaultMessage: "Québec",
  },
  provinceSaskatchewan: {
    id: "WorkForm.Province.Saskatchewan",
    defaultMessage: "Saskatchewan",
  },
  provinceNewfoundlandLabrador: {
    id: "WorkForm.Province.NewfoundlandLabrador",
    defaultMessage: "Terre-Neuve-et-Labrador",
  },
  provinceNorthwestTerritories: {
    id: "WorkForm.Province.NorthwestTerritories",
    defaultMessage: "Territoires du Nord-Ouest",
  },
  provinceYukon: {
    id: "WorkForm.Province.Yukon",
    defaultMessage: "Yukon",
  },
  summary: {
    id: "WorkForm.Summary",
    defaultMessage: "Sommaire des travaux",
  },
});

export const WorkForm: React.FC = () => { 

  const { formatMessage } = useIntl();
  const validations = useFormValidations();
  const { control, formState } = useFormContext();

  return (
    <>
      <Grid item xs={12} sm={6} className="tw-mt-4">
        <Controller
          control={control}
          name="workInformation.propositionNumber"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="proposition-number"
              label={formatMessage(m.propositionNumber)}
              fullWidth
              autoComplete="proposition-number"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="tw-mt-4">
        <Controller
          control={control}
          name="workInformation.date"
          rules={{ validate: validations.date }}
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <MuiPickersUtilsProvider utils={DayjsUtils}>
              <KeyboardDatePicker
                error={Boolean(formState.errors.workInformation?.date)}
                helperText={formState.errors.workInformation?.date.message}
                autoOk
                variant="inline"
                inputVariant="outlined"
                size="small"
                fullWidth
                id="workdatepicker"
                label={formatMessage(m.date)}
                format="DD/MM/YYYY"
                inputRef={ref}
                KeyboardButtonProps={{
                  "aria-label": "date",
                }}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
              />
            </MuiPickersUtilsProvider>
          )}
        /> 
      </Grid>
      <Grid item xs={12} className="tw-mt-4 tw-py-0">
        <FormControl component="fieldset">
          <FormLabel component="legend" >{formatMessage(m.language)}</FormLabel>
          <Controller
            control={control}
            name="workInformation.language"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <RadioGroup 
                aria-label="language" 
                value={value}
                onBlur={onBlur}
                onChange={onChange}
                ref={ref}
                row 
                style={{height:"32px"}}>
                <FormControlLabel value="fr" control={<Radio size="small" />} label={formatMessage(m.languageFrench)} />
                <FormControlLabel value="en" control={<Radio size="small" />} label={formatMessage(m.languageEnglish)} />
              </RadioGroup>
            )}
          />

        </FormControl>
      </Grid>
      <Grid item xs={12} sm={8} className="tw-mt-4">
        <Controller
          control={control}
          name="workInformation.street"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="workstreet"
              label={formatMessage(m.street)}
              fullWidth
              autoComplete="street"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={4} className="tw-mt-4">
        <Controller
          control={control}
          name="workInformation.postalCode"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="workpostalCode"
              label={formatMessage(m.postalCode)}
              fullWidth
              autoComplete="postalCode"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="tw-mt-4">
        <Controller
          control={control}
          name="workInformation.city"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              disabled
              variant="outlined"
              id="workcity"
              label={formatMessage(m.city)}
              fullWidth
              autoComplete="city"
              size="small"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} className="tw-mt-4">
        <FormControl variant="outlined" size="small" className="tw-w-full">
          <InputLabel id="client-province">{formatMessage(m.province)}</InputLabel>
          <Controller
            control={control}
            name="workInformation.province"
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <Select
                disabled
                labelId="client-province"
                label={formatMessage(m.province)}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                ref={ref}
              >
                <MenuItem value={Provinces.Alberta}>{formatMessage(m.provinceAlberta)}</MenuItem>
                <MenuItem value={Provinces.BritishColumbia}>{formatMessage(m.provinceBritishColumbia)}</MenuItem>
                <MenuItem value={Provinces.PrinceEdwardIsland}>{formatMessage(m.provincePrinceEdwardIsland)}</MenuItem>
                <MenuItem value={Provinces.Manitoba}>{formatMessage(m.provinceManitoba)}</MenuItem>
                <MenuItem value={Provinces.NewBrunswick}>{formatMessage(m.provinceNewBrunswick)}</MenuItem>
                <MenuItem value={Provinces.NovaScotia}>{formatMessage(m.provinceNovaScotia)}</MenuItem>
                <MenuItem value={Provinces.Nunavut}>{formatMessage(m.provinceNunavut)}</MenuItem>
                <MenuItem value={Provinces.Ontario}>{formatMessage(m.provinceOntario)}</MenuItem>
                <MenuItem value={Provinces.Quebec}>{formatMessage(m.provinceQuebec)}</MenuItem>
                <MenuItem value={Provinces.Saskatchewan}>{formatMessage(m.provinceSaskatchewan)}</MenuItem>
                <MenuItem value={Provinces.NewfoundlandLabrador}>{formatMessage(m.provinceNewfoundlandLabrador)}</MenuItem>
                <MenuItem value={Provinces.NorthwestTerritories}>{formatMessage(m.provinceNorthwestTerritories)}</MenuItem>
                <MenuItem value={Provinces.Yukon}>{formatMessage(m.provinceYukon)}</MenuItem>
              </Select>
            )}
          />
        </FormControl>
      </Grid>
      <Grid item xs={12} className="tw-mt-4">
        <Controller
          control={control}
          name="workInformation.summary"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <TextField
              id="worksummary"
              label={formatMessage(m.summary)}
              multiline
              fullWidth
              rows={7}
              variant="outlined"
              onChange={onChange}
              onBlur={onBlur}
              value={value}
              ref={ref}
            />
          )}
        />
      </Grid>
    </>
  );
};