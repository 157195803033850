import { AppBar, Box, Button, Container, Grid, Toolbar, Typography } from "@material-ui/core";
import { Footer } from "components/Layout/Footer";
import React from "react";
import { defineMessages, useIntl } from "react-intl";
import { useHistory } from "react-router";
import { Link } from "react-router-dom";

const m = defineMessages({
  title: {
    id: "Errors.Title",
    defaultMessage: "Générateur de soumission",
  },
  home: {
    id: "Errors.ErrorPage.Home",
    defaultMessage: "Accueil",
  },
  back: {
    id: "Errors.ErrorPage.Back",
    defaultMessage: "Retour",
  },
  login: {
    id: "Errors.ErrorPage.Login",
    defaultMessage: "S'identifier",
  }
});

interface Props {
  title: string;
  message: string;
  errorCode?: number;
  svgImage: JSX.Element;
}

export const ErrorPage: React.FC<Props> = ({ title, message, errorCode, svgImage }: Props) => {
  const { formatMessage } = useIntl();
  const history = useHistory();

  const handleLoginButton = () => history.push("/login");
  const handleHomeButton = () => history.push("/");

  return (
    <Box className="tw-flex tw-flex-col tw-min-h-screen">
      <AppBar position="sticky" color="inherit">
        <Toolbar>
          <Grid container
            direction="row"
            justify="space-between"
            alignItems="center">
            <Grid item xs={3}>
              <Link to="/">
                <img src="/images/logo.png" alt="logo" style={{maxHeight:64}} className="tw-py-2 tw-inline" />
              </Link>
            </Grid>
            <Grid item xs className="tw-flex tw-justify-center">
              <Link to="/">
                <Typography variant="h6" className="tw-leading-4">{formatMessage(m.title)}</Typography>
              </Link>
            </Grid>
            <Grid item xs={3} className="tw-flex tw-justify-end"></Grid>
          </Grid>
        </Toolbar>
      </AppBar> 
      <Box className="tw-flex tw-flex-grow tw-py-10">
        <Container maxWidth="sm" className="tw-flex tw-flex-col tw-justify-center">
          <Grid container direction="column" justify="center" alignItems="center">
            {svgImage}
            <Typography variant="h4" align="center" className="tw-py-4">{title}</Typography>
            <Typography paragraph align="center">{message}</Typography>
            <Grid container justify="space-around" alignItems="center" className="tw-pt-4">
              {errorCode === 401 || errorCode === 403 ? (
                <Button variant="contained" color="primary" onClick={handleLoginButton}>{formatMessage(m.login)}</Button>
              ):(
                <Button variant="contained" color="primary" onClick={handleHomeButton}>{formatMessage(m.home)}</Button>
              )}        
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </Box>
  );
};