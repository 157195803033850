import { Reducer } from "react";
import { ApplicationActionTypes, ApplicationState } from ".";
import * as actionsTypes from "./actionTypes";

const initialState: ApplicationState = {
  isLoaded: false,
  language: "fr-CA",
};

export const applicationReducer: Reducer<ApplicationState, ApplicationActionTypes> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case actionsTypes.APPLICATION_STARTED: 
      return {
        ...state,
        isLoaded: true,
        language: action.payload
      };
    default:
      return state;
  }
};
