import React, { useEffect } from "react";
import { defineMessages, useIntl } from "react-intl";
import {
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  InputAdornment,
  InputLabel,
  TextField,
  OutlinedInput,
  Typography,
  Slider
} from "@material-ui/core";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { useFormValidations } from "../hooks/useFormValidations";
import { EpsilonRound } from "helper";
import { FormValues } from "./QuoteGeneratorForm";

const m = defineMessages({
  workAmount: {
    id: "WorkCostForm.WorkCost",
    defaultMessage: "Montant des travaux",
  },
  gstAmount: {
    id: "WorkCostForm.GstAmount",
    defaultMessage: "TPS (5%)",
  },
  pstAmount: {
    id: "WorkCostForm.PstAmount",
    defaultMessage: "TVQ (9.975%)",
  },
  totalAmount: {
    id: "WorkCostForm.TotalAmount",
    defaultMessage: "Total",
  },
  paymentModality: {
    id: "WorkCostForm.PaymentModality",
    defaultMessage: "Modalités de paiement",
  },
  paymentModalitySignatureLabel: {
    id: "PaymentModalitySignatureLabel",
    defaultMessage: "À la signature du contract",
  },
  paymentModalityMobilisationPercentageLabel: {
    id: "PaymentModalityMobilisationPercentageLabel",
    defaultMessage: "À la mobilisation",
  },
  paymentModalityHalfworkPercentageLabel: {
    id: "PaymentModalityHalfworkPercentageLabel",
    defaultMessage: "À la mi-travaux",
  },
  paymentModalityBalanceValidationError: {
    id: "PaymentModalityBalanceValidationError",
    defaultMessage: "La balance doit être entre 0 et 100.",
  }
});

export const WorkCostForm: React.FC = () => {
  const { formatMessage } = useIntl();
  const { control, formState, setValue, clearErrors } = useFormContext<FormValues>();
  
  const formAmount = useWatch({ control, name: "workCost.amount" });
  const formTotalAmount = useWatch({ control, name: "workCost.totalAmount" });

  const paymentModalitySignaturePercentage = useWatch({ control, name: "workCost.paymentModalitySignaturePercentage" });
  const paymentModalityMobilisationPercentage = useWatch({ control, name: "workCost.paymentModalityMobilisationPercentage" });
  const paymentModalityHalfworkPercentage = useWatch({ control, name: "workCost.paymentModalityHalfworkPercentage" });
  const validations = useFormValidations();

  useEffect(() => {
    const amount = Number(formAmount);
    const gstAmount = +EpsilonRound(amount * 0.05).toFixed(2);
    const pstAmount = +EpsilonRound(amount * 0.09975).toFixed(2);
    const totalAmount = +EpsilonRound(amount + gstAmount + pstAmount).toFixed(2);
    setValue("workCost.gstAmount", gstAmount);
    setValue("workCost.pstAmount", pstAmount);
    setValue("workCost.totalAmount", totalAmount);
  }, [formAmount]);

  useEffect(() => {
    const amount = Number(EpsilonRound(100-paymentModalitySignaturePercentage-paymentModalityMobilisationPercentage-paymentModalityHalfworkPercentage).toFixed(2));
    if(amount >= 0 && amount <= 100) {
      clearErrors("workCost.paymentModalityBalancePercentage");
    }
    setValue("workCost.paymentModalityBalancePercentage", amount);
  }, [paymentModalitySignaturePercentage, paymentModalityMobilisationPercentage, paymentModalityHalfworkPercentage]);

  const montantModalitySignature = EpsilonRound(formTotalAmount * (paymentModalitySignaturePercentage/100)).toFixed(2);
  const montantModalityMobilisation = EpsilonRound(formTotalAmount * (paymentModalityMobilisationPercentage/100)).toFixed(2);
  const montantModalityHalfwork = EpsilonRound(formTotalAmount * (paymentModalityHalfworkPercentage/100)).toFixed(2);

  const montantModalityBalance = EpsilonRound(formTotalAmount - parseFloat(montantModalitySignature) - parseFloat(montantModalityMobilisation) - parseFloat(montantModalityHalfwork)).toFixed(2);

  return (
    <>
      <Grid container>
        <Grid item xs={12} className='tw-mt-4'>
          <FormControl
            size='small'
            variant='outlined'
            error={Boolean(formState.errors.workCost?.amount)}>
            <InputLabel required htmlFor='work-amount'>{formatMessage(m.workAmount)}</InputLabel>
            <Controller
              control={control}
              rules={{ validate: validations.amount }}
              name='workCost.amount'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  required
                  type='number'
                  id='work-amount'
                  startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                  label={formatMessage(m.workAmount)}
                  error={Boolean(formState.errors.workCost?.amount)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ min: 0, step:"0.01", style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
            {Boolean(formState.errors.workCost?.amount) && (
              <FormHelperText error>{formState.errors.workCost?.amount?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Divider orientation='horizontal' className='tw-w-full tw-mt-4' />
        <Grid item xs={12} md={3} className="tw-mt-4">
          <FormControl
            size='small'
            variant='outlined'
            error={Boolean(formState.errors.workCost?.gstAmount)}>
            <InputLabel htmlFor='gst-amount'>{formatMessage(m.gstAmount)}</InputLabel>
            <Controller
              control={control}
              name='workCost.gstAmount'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  disabled
                  type='number'
                  id='gst-amount'
                  startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                  label={formatMessage(m.gstAmount)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
            {Boolean(formState.errors.workCost?.gstAmount) && (
              <FormHelperText error>{formState.errors.workCost?.gstAmount?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} className="tw-mt-4">
          <FormControl
            size='small'
            variant='outlined'
            error={Boolean(formState.errors.workCost?.pstAmount)}>
            <InputLabel htmlFor='pst-amount'>{formatMessage(m.pstAmount)}</InputLabel>
            <Controller
              control={control}
              name='workCost.pstAmount'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  disabled
                  type='number'
                  id='pst-amount'
                  startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                  label={formatMessage(m.pstAmount)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
            {Boolean(formState.errors.workCost?.pstAmount) && (
              <FormHelperText error>{formState.errors.workCost?.pstAmount?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3} className="tw-mt-4">
          <FormControl
            size='small'
            variant='outlined'
            error={Boolean(formState.errors.workCost?.totalAmount)}>
            <InputLabel htmlFor='total-amount'>{formatMessage(m.totalAmount)}</InputLabel>
            <Controller
              control={control}
              name='workCost.totalAmount'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  disabled
                  type='number'
                  id='total-amount'
                  startAdornment={<InputAdornment position='start'>$</InputAdornment>}
                  label={formatMessage(m.totalAmount)}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
            {Boolean(formState.errors.workCost?.totalAmount) && (
              <FormHelperText error>{formState.errors.workCost?.totalAmount?.message}</FormHelperText>
            )}
          </FormControl>
        </Grid>
      </Grid>
      <Typography variant="subtitle2" className='tw-mt-4'>{formatMessage(m.paymentModality)}</Typography>
      <Grid container className='tw-mt-4'>
        <Grid item md={12}>
          <Typography variant="body2" className='tw-text-gray-500'>{formatMessage(m.paymentModalitySignatureLabel)}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="tw-flex tw-items-center tw-pr-4">
          <Controller
            name="workCost.paymentModalitySignaturePercentage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Slider
                className="tw-mr-4"
                onChange={(_, value) => {
                  onChange(value);
                }}
                value={value}
                max={100}
                step={1}
              />
            )}
          />
          <FormControl
            size='small'
            required
            className="tw-w-36"
            variant='outlined'>
            <Controller
              control={control}
              name='workCost.paymentModalitySignaturePercentage'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  required
                  error={Boolean(formState.errors.workCost?.paymentModalityBalancePercentage)}
                  type='number'
                  id='work-paiment-modality-signature-percentage'
                  startAdornment={<InputAdornment position='start'>%</InputAdornment>}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ min: 0, max: 100, step:"1", style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            size="small"
            type='number'
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={montantModalitySignature}
          />
        </Grid>
      </Grid>
      <Grid container className='tw-mt-4'>
        <Grid item md={12}>
          <Typography variant="body2" className='tw-text-gray-500'>{formatMessage(m.paymentModalityMobilisationPercentageLabel)}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="tw-flex tw-items-center tw-pr-4">
          <Controller
            name="workCost.paymentModalityMobilisationPercentage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Slider
                className="tw-mr-4"
                onChange={(_, value) => {
                  onChange(value);
                }}
                value={value}
                max={100}
                step={1}
              />
            )}
          />
          <FormControl
            size='small'
            className="tw-w-36"
            variant='outlined'>
            <Controller
              control={control}
              name='workCost.paymentModalityMobilisationPercentage'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  required
                  error={Boolean(formState.errors.workCost?.paymentModalityBalancePercentage)}
                  type='number'
                  id='work-paiment-modality-mobilisation-percentage'
                  startAdornment={<InputAdornment position='start'>%</InputAdornment>}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ min: 0, max: 100, step:"1", style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            size="small"
            type='number'
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={montantModalityMobilisation}
          />
        </Grid>
      </Grid>
      <Grid container className='tw-mt-4'>
        <Grid item md={12}>
          <Typography variant="body2" className='tw-text-gray-500'>{formatMessage(m.paymentModalityHalfworkPercentageLabel)}</Typography>
        </Grid>
        <Grid item xs={12} md={6} className="tw-flex tw-items-center tw-pr-4">
          <Controller
            name="workCost.paymentModalityHalfworkPercentage"
            control={control}
            render={({ field: { onChange, value } }) => (
              <Slider
                className="tw-mr-4"
                onChange={(_, value) => {
                  onChange(value);
                }}
                value={value}
                max={100}
                step={1}
              />
            )}
          />
          <FormControl
            size='small'
            className="tw-w-36"
            variant='outlined'>
            <Controller
              control={control}
              name='workCost.paymentModalityHalfworkPercentage'
              render={({ field: { onChange, onBlur, value, ref } }) => (
                <OutlinedInput
                  required
                  error={Boolean(formState.errors.workCost?.paymentModalityBalancePercentage)}
                  type='number'
                  id='work-paiment-modality-halfwork-percentage'
                  startAdornment={<InputAdornment position='start'>%</InputAdornment>}
                  onChange={onChange}
                  onBlur={onBlur}
                  value={value}
                  inputProps={{ min: 0, max: 100, step:"1", style: { textAlign: "right" } }}
                  ref={ref}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            size="small"
            type='number'
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={montantModalityHalfwork}
          />
        </Grid>
      </Grid>
      <Divider orientation='horizontal' className='tw-w-full tw-mt-4' />
      <Grid container className='tw-mt-4'>
        <Grid item xs={12} md={6} className="tw-flex tw-items-center tw-justify-between tw-pr-4">
          <Typography variant="body2" className='tw-text-gray-500 tw-w-full tw-text-right tw-pr-4'>Balance fin des travaux</Typography>
          <FormControl
            size='small'
            className="tw-w-36"
            variant='outlined'>
            <Controller
              control={control}
              name='workCost.paymentModalityBalancePercentage'
              rules={{ validate: validations.balance }}
              render={({ field: { value, ref, onChange } }) => (
                <OutlinedInput
                  readOnly
                  error={Boolean(formState.errors.workCost?.paymentModalityBalancePercentage)}
                  type='number'
                  startAdornment={<InputAdornment position='start'>%</InputAdornment>}
                  value={value}
                  onChange={onChange}
                  ref={ref}
                  inputProps={{ style: { textAlign: "right" } }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} md={3}>
          <TextField
            disabled
            size="small"
            type='number'
            variant="outlined"
            InputProps={{
              startAdornment: <InputAdornment position="start">$</InputAdornment>,
            }}
            value={montantModalityBalance}
          />
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} md={6} className="tw-flex tw-justify-end tw-pr-4">
          {Boolean(formState.errors.workCost?.paymentModalityBalancePercentage) && (
            <FormHelperText error>{formatMessage(m.paymentModalityBalanceValidationError)}</FormHelperText>
          )}
        </Grid>
      </Grid>
    </>
  );
};
