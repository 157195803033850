export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID || "",
    authority: "https://login.microsoftonline.com/organizations",
    redirectUri: "/"
  },
  cache: {
    cacheLocation: "localStorage",
    storeAuthStateInCookie: false 
  },
};

export const loginRequest = {
  scopes: ["offline_access", "user.read"],
  redirectStartPage: "/",
  prompt: "select_account"
};

export const silentRequest = {
  scopes: ["offline_access", "user.read"],
  forceRefresh: false
};
