import { AppBar, Box, Button, Grid, ListItemIcon, ListItemText, makeStyles, Menu, MenuItem, MenuProps, Toolbar, Typography, withStyles } from "@material-ui/core";
import ExitToAppIcon from "@material-ui/icons/ExitToApp";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMsal } from "@azure/msal-react";
import { RootState } from "store";
import { defineMessages, useIntl } from "react-intl";
import { UserAvatar } from "components/UserAvatar";
import { Link } from "react-router-dom";
import { clearAccount } from "modules/account/actions";
import SettingsIcon from "@material-ui/icons/Settings";

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
}));

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props: JSX.IntrinsicAttributes & MenuProps) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const m = defineMessages({
  title: {
    id: "TopBar.Title",
    defaultMessage: "Générateur de soumission",
  },
  logout: {
    id: "TopBar.Logout",
    defaultMessage: "Se déconnecter",
  },
});

export const TopBar: React.FC = () => {  
  const { formatMessage } = useIntl(); 
  const userName = useSelector<RootState, string>(state => state.account.user?.name || "");
  const isDemoUser = useSelector<RootState, boolean>(state => state.account.isDemoUser);
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const dispatch = useDispatch();
  const open = Boolean(anchorEl);
  const { instance } = useMsal();
  const classes = useStyles();

  const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    if(isDemoUser) {
      dispatch(clearAccount());
    }
    else
    {
      instance.logoutRedirect({postLogoutRedirectUri: "/"});
    }
  };

  return (
    <AppBar position="sticky" color="inherit" className={classes.appBar}>
      <Toolbar>
        <Grid container
          direction="row"
          justify="space-between"
          alignItems="center">
          <Grid item xs={3}>
            <Link to="/" className="tw-flex tw-items-center tw-text-primary">
              <img src="/images/logo.png" alt="logo" style={{maxHeight:64}} className="tw-py-2 tw-inline" />
              <Typography variant="h6" className="tw-ml-2 tw-whitespace-nowrap">Polar Breeze Ltd</Typography>
            </Link>
          </Grid>
          <Grid item xs className="tw-flex tw-justify-center">
            <Link to="/">
              <Typography variant="h6" className="tw-leading-4">{formatMessage(m.title)}</Typography>
            </Link>
          </Grid>
          <Grid item xs={3} className="tw-flex tw-justify-end">
            <Box className="tw-flex tw-items-center">
              <Button
                aria-label="current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleMenu}
                endIcon={<ArrowDropDownIcon />}
              >
                <UserAvatar userName={userName} className="tw-mr-2" />
                <Typography variant="body2" className="tw-capitalize">{userName}</Typography>
              </Button>
              <StyledMenu
                id="menu-appbar"
                anchorEl={anchorEl}
                keepMounted
                open={open}
                onClose={handleClose}
              >
                <MenuItem component={Link} to="/admin">
                  <ListItemIcon>
                    <SettingsIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={"Administration"} />
                </MenuItem>
                <MenuItem onClick={handleLogout}>
                  <ListItemIcon>
                    <ExitToAppIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary={formatMessage(m.logout)} />
                </MenuItem>
              </StyledMenu>
            </Box>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>  
  );
};