import React, { useEffect } from "react";
import { Backdrop, Box, Button, CircularProgress, Container, createStyles, Grid, makeStyles, Theme, Typography } from "@material-ui/core";
import { ContactForm } from "../components/ContactForm";
import { FormContainer } from "../components/FormContainer";
import { WorkForm } from "../components/WorkForm";
import { WorkDescriptionForm } from "../components/WorkDescriptionForm";
import { SalesPersonInformationForm } from "../components/SalesPersonInformationForm";
import { WorkCostForm } from "../components/WorkCostForm";
import { defineMessages, useIntl } from "react-intl";
import { FormProvider, useFieldArray, useForm } from "react-hook-form";
import {
  ClauseValue,
  Proposition,
  Provinces,
  Titles,
  WorkType,
  WorkTypeOptions,
} from "../types";
import { GenerateQuotationApiData, QuotationsApi } from "../api";
import { defaultErrorHandler } from "api";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
  }),
);

const m = defineMessages({
  contactInformationTitle: {
    id: "QuoteGenerator.ContactInformation.Title",
    defaultMessage: "Information Contact",
  },
  contactInformationSubTitle: {
    id: "QuoteGenerator.ContactInformation.SubTitle",
    defaultMessage: "Information de contact du client",
  },
  workInformationTitle: {
    id: "QuoteGenerator.WorkInformation.Title",
    defaultMessage: "Information Travaux",
  },
  workInformationSubTitle: {
    id: "QuoteGenerator.WorkInformation.SubTitle",
    defaultMessage: "Information sur les travaux",
  },
  workDescriptionTitle: {
    id: "QuoteGenerator.WorkDescription.Title",
    defaultMessage: "Descriptions Travaux",
  },
  workDescriptionSubTitle: {
    id: "QuoteGenerator.WorkDescription.SubTitle",
    defaultMessage: "Descriptions des travaux",
  },
  workCostTitle: {
    id: "QuoteGenerator.WorkCost.Title",
    defaultMessage: "Coût des travaux",
  },
  workCostSubTitle: {
    id: "QuoteGenerator.WorkCost.SubTitle",
    defaultMessage: "Coût des travaux",
  },  
  salesPersonInformationTitle: {
    id: "QuoteGenerator.SalesPersonInformation.Title",
    defaultMessage: "Information Vendeur",
  },
  salesPersonInformationSubTitle: {
    id: "QuoteGenerator.SalesPersonInformation.SubTitle",
    defaultMessage: "Information du vendeur",
  },
  generate: {
    id: "QuoteGenerator.Generate",
    defaultMessage: "Générer",
  },
  generationInProgress: {
    id: "QuoteGenerator.GenerationInProgress",
    defaultMessage: "Génération en cours",
  },
  formNotValideError: {
    id: "QuoteGenerator.FormNotValideError",
    defaultMessage: "Le formulaire n'est pas valide.",
  },
});

export type FormValues = {
  contactInformation: {
    contacts: {
      title: Titles;
      name: string;
    }[];
    companyName: string;
    street: string;
    postalCode: string;
    city: string;
    province: Provinces;
    telephone: string;
    cellphone: string;
    officephone: string;
    email: string;
  };
  workInformation: {
    propositionNumber: string;
    date: Date;
    language: string;
    street: string;
    postalCode: string;
    city: string;
    province: Provinces;
    summary: string;
  };
  workDescription: {
    workTypes: WorkTypeOptions[];
    clauses: ClauseValue[];
    otherWorkClause: string;
    amount: number;
    additionalSpecifications: string;
    hasStages: boolean;
  };
  workCost: {
    amount: number;
    paymentModalitySignaturePercentage: number;
    paymentModalityMobilisationPercentage: number;
    paymentModalityHalfworkPercentage: number;
    paymentModalityBalancePercentage: number;
    gstAmount: number;
    pstAmount: number;
    totalAmount: number;
    remarks: string;
  }
  salesPersonInformation: {
    name: string;
    function: string;
    abreviation: string;
  };
};

export interface QuoteGeneratorFormProps {
  proposition: Proposition;
  workTypes: WorkType[];
}

export const QuoteGeneratorForm: React.FC<QuoteGeneratorFormProps> = (
  props: QuoteGeneratorFormProps
) => {
  const { formatMessage } = useIntl();
  const { enqueueSnackbar } = useSnackbar();
  const [isSubmitting, setIsSubmitting] = React.useState(false);
  const classes = useStyles();
  const { proposition, workTypes } = props;

  const methods = useForm<FormValues>({
    mode: "onSubmit",
    defaultValues: {
      contactInformation: {
        cellphone: proposition.clientCellPhone,
        city: proposition.clientCity,
        email: proposition.clientEmail,
        postalCode: proposition.clientPostalCode,
        province: proposition.clientProvince,
        street: proposition.clientStreet,
        telephone: proposition.clientTelephone,
        officephone: proposition.clientOfficePhone,
        companyName: proposition.clientCompanyName,
        contacts: [
          { name: proposition.client1Name, title: proposition.client1Title },
          { name: proposition.client2Name, title: proposition.client2Title },
        ],
      },
      workInformation: {
        city: proposition.workCity,
        postalCode: proposition.workPostalCode,
        propositionNumber: proposition.propositionNumber,
        province: proposition.workProvince,
        street: proposition.workStreet,
        language: proposition.language,
        date: new Date(),
        summary: proposition.workRemarks,
      },
      workDescription: {
        clauses: [],
        workTypes: [],
        otherWorkClause: "",
        amount: 0,
        additionalSpecifications: "",
        hasStages: false,
      },
      workCost: {
        amount: proposition.workCostEstimate || 0,
        paymentModalitySignaturePercentage: 15,
        paymentModalityMobilisationPercentage: 35,
        paymentModalityHalfworkPercentage: 25,
        remarks: "",
        totalAmount: 0,
        gstAmount: 0,
        pstAmount: 0
      },
      salesPersonInformation: {
        abreviation: proposition.salesPersonAbreviation,
        function: proposition.salesPersonFunction,
        name: proposition.salesPersonName,
      },
    },
  });

  const { control, formState: {errors, isSubmitting: formStateIsSubmitting}} = methods;
  const fieldArrayMethods = useFieldArray({
    control,
    name: "workDescription.clauses",
    keyName: "key"
  });

  useEffect(() => {
    if(formStateIsSubmitting){
      const allErrors = Object.keys(errors);
      if(allErrors.length > 0) {
        enqueueSnackbar(formatMessage(m.formNotValideError), {
          variant: "error",
          anchorOrigin: {
            vertical: "bottom",
            horizontal: "center",
          },
        });
      }
    }  
  }, [errors, formStateIsSubmitting]);

  const handleOnSubmit =async (data: FormValues) => {   

    setIsSubmitting(true);

    const apiData: GenerateQuotationApiData = {
      opportunityId: proposition.opportunityId,
      date: data.workInformation.date.toISOString(),
      language: data.workInformation.language,
      summary: data.workInformation.summary,
      amount: data.workCost.amount,
      paymentModalitySignaturePercentage: data.workCost.paymentModalitySignaturePercentage,
      paymentModalityMobilisationPercentage: data.workCost.paymentModalityMobilisationPercentage,
      paymentModalityHalfworkPercentage: data.workCost.paymentModalityHalfworkPercentage,
      additionalSpecifications: data.workDescription.additionalSpecifications,
      worktypesIds: data.workDescription.workTypes.map((value) => Number(value.id)),
      hasStages: data.workDescription.hasStages,
      clauses: data.workDescription.clauses
        .filter((c) => c.isEnable)
        .map((c) => ({
          id: c.id,
          order: c.order,
          responsible: c.responsible,
          variables: c.variables,
        })),
      otherWorkClause: data.workDescription.otherWorkClause,
      salesPersonName: data.salesPersonInformation.name,
      salesPersonFunction: data.salesPersonInformation.function,
      salesPersonAbreviation: data.salesPersonInformation.abreviation,
    };
  
    QuotationsApi
      .generateQuotation(apiData)
      .then((response) => {
        const downloadUrl = window.URL.createObjectURL(response.data);
        const link = document.createElement("a");
        link.href = downloadUrl;
        link.setAttribute("download", `Soumission ${proposition.propositionNumber}.docx`);
        document.body.appendChild(link);
        link.click();
        link.remove();
        window.URL.revokeObjectURL(link.href);
      })
      .catch((error) => {
        defaultErrorHandler(error);
      })
      .finally(() => {
        setIsSubmitting(false);
      });  
  };

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(handleOnSubmit)} noValidate>
        <Box className='tw-grid tw-gap-4 tw-grid-cols-1 tw-mt-8 tw-mb-4 md:tw-grid-cols-2'>
          <FormContainer
            title={formatMessage(m.contactInformationTitle)}
            subTitle={formatMessage(m.contactInformationSubTitle)}>
            <ContactForm />
          </FormContainer>
          <FormContainer
            title={formatMessage(m.workInformationTitle)}
            subTitle={formatMessage(m.workInformationSubTitle)}>
            <WorkForm />
          </FormContainer>
        </Box>
        <FormContainer
          title={formatMessage(m.workDescriptionTitle)}
          subTitle={formatMessage(m.workDescriptionSubTitle)}>
          <WorkDescriptionForm workTypes={workTypes} fieldArrayMethods={fieldArrayMethods} />
        </FormContainer>
        <Box className='tw-mt-4'>
          <FormContainer
            title={formatMessage(m.workCostTitle)}
            subTitle={formatMessage(m.workCostSubTitle)}>
            <WorkCostForm />
          </FormContainer>
        </Box>
        <Box className='tw-mt-4'>
          <FormContainer
            title={formatMessage(m.salesPersonInformationTitle)}
            subTitle={formatMessage(m.salesPersonInformationSubTitle)}>
            <SalesPersonInformationForm />
          </FormContainer>
        </Box>
        <Box className='tw-mt-4 tw-flex tw-justify-end'>
          <Button variant='contained' color='primary' type='submit' className=''>
            {formatMessage(m.generate)}
          </Button>
        </Box>
      </form>
      <Backdrop className={classes.backdrop} open={isSubmitting}>
        <Container maxWidth="lg">
          <Grid container direction="column" justify="center" alignItems="center">
            <Typography variant="h2" align="center" className="tw-mb-4">{formatMessage(m.generationInProgress)}</Typography>
            <CircularProgress color="inherit" />
          </Grid>
        </Container>
      </Backdrop>
    </FormProvider>
  );
};
